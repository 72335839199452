import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Route from './Route';
import Loader from '../components/Loader';
import Layout from '../components/Layout';
import UserModal from '../components/UserModal';

const Login = lazy(() => import('../screens/Login'));
const LoginLink = lazy(() => import('../screens/LoginLink'));

const Logout = lazy(() => import('../screens/Logout'));
const ForgottenPassword = lazy(() => import('../screens/ForgottenPassword'));
const VerifyEmail = lazy(() => import('../screens/VerifyEmail'));
const ResetPassword = lazy(() =>
  import('../screens/ForgottenPassword/ResetForm'),
);
const AcceptInvitation = lazy(() => import('../screens/AcceptInvitation'));
const Register = lazy(() => import('../screens/Register'));
const Dashboard = lazy(() => import('../screens/Dashboard'));
const Flows = lazy(() => import('../screens/Flows'));
const Integration = lazy(() => import('../screens/Integration'));
const Users = lazy(() => import('../screens/Users'));
const Activity = lazy(() => import('../screens/Activity'));
const Subscription = lazy(() => import('../screens/Subscription'));
const Settings = lazy(() => import('../screens/Settings'));
const Error404 = lazy(() => import('../screens/Errors/Error404'));

const AppSumoLogin = lazy(() => import('../screens/AppSumoLogin'));
const WebSummitPage = lazy(() => import('../screens/WebSummitPage'));

const Routes = () => {
  const loading = useSelector(state => state.auth.loading);

  if (loading) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        <Switch>
          <Route
            guarded
            path="/dashboard"
            component={Dashboard}
            active="dashboard"
          />
          <Route guarded path="/flows" component={Flows} active="flows" />
          <Route
            guarded
            path="/integration"
            component={Integration}
            active="integration"
          />
          <Route guarded path="/users" component={Users} active="users" />
          <Route
            guarded
            path="/activity"
            component={Activity}
            active="activity"
            exact
          />
          <Route
            guarded
            exact
            path="/activity/user/:id"
            render={() => {
              return <UserModal />;
            }}
            active="activity"
          />
          <Route
            guarded
            path="/subscription"
            component={Subscription}
            active="subscription"
          />
          <Route
            guarded
            path="/settings"
            component={Settings}
            active="settings"
          />

          {/* Registration and login */}
          <Route path="/email/verify/:user" component={VerifyEmail} />
          <Route guest path="/login" component={Login} fallback="/dashboard" />
          <Route
            guest
            path="/authenticate-with-link"
            component={LoginLink}
            fallback="/dashboard?change-password"
          />
          <Route
            guest
            path="/reset-password"
            component={ResetPassword}
            fallback="/dashboard"
          />
          <Route
            guest
            path="/forgotten-password"
            component={ForgottenPassword}
            fallback="/dashboard"
          />
          <Route
            guest
            path="/register"
            component={Register}
            fallback="/dashboard"
          />
          <Route path="/invitation/:token" component={AcceptInvitation} />
          <Route path="/appsumo/:token" component={AppSumoLogin} />
          <Route path="/goodbye" component={Logout} />
          <Route path="/websummit" component={WebSummitPage} />

          <Route exact path="/" guestRoute="/login" guardedRoute="/dashboard" />

          <Route component={Error404} />
        </Switch>
      </Layout>
    </Suspense>
  );
};

export default Routes;
