import { combineReducers } from 'redux';

import auth from './auth';
import layout from './layout';
import flows from './flows';
import targeting from './targeting';
import userFields from './user-fields';
import activity from './activity';
import settings from './settings';
import users from './users';
import integrations from './integrations';
import subscription from './subscription';
import bookmarks from './bookmarks';
import dashboard from './dashboard';

const rootReducer = combineReducers({
  auth,
  layout,
  flows,
  targeting,
  userFields,
  activity,
  settings,
  users,
  subscription,
  integrations,
  bookmarks,
  dashboard,
});

export default rootReducer;
