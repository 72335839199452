const en = {
  app_name: 'Bonboarding',

  seo_title: 'Bonboarding | Boost Your User Adoption',
  seo_description:
    'Create stunning onboarding experiences and interactive walkthroughs. And never lose users because of confusion.',

  // Generic terms
  continue: 'Continue',
  copy_value: 'Copy value',
  copied: 'Copied!',

  // Feedback
  feedback_label: 'Feedback',
  feedback_type: 'What would you like to report?',
  feedback_type_bug: 'Bug',
  feedback_type_feedback: 'Feedback',
  feedback_type_suggestion: 'Suggestion',
  feedback_type_error: 'Please choose a type',
  feedback_message: 'Your feedback',
  feedback_message_error: 'Please enter a message',
  feedback_send: 'Send',
  feedback_api_error: 'Oops. Something went wrong',
  feedback_unknown_error: 'Please try again, or write an email to ',
  feedback_success_title: 'Thanks for your feedback',
  feedback_success_message: `We really appreciate that you took the time and write to us.`,
  feedback_success_message_2: `Your feedback is truly valuable, and helps us to constantly improve Bonboarding.`,
  feedback_chat_with_us: 'Or chat with us',

  // Login
  login_form_email: 'Email',
  login_form_password: 'Password',
  login_form_submit: 'Submit',
  login_error: 'Oh no! You probably mistyped your email or password',
  login_error_forgotten_password: 'Maybe try to reset your password',
  login_link_to_forgotten_password: 'Reset your password',
  login_no_account: 'No account? No problem!',
  login_link_to_register: 'Sign up now!',
  login_logging_in: 'Logging you in',
  login_magic_title: 'We sent an email with a link to sign in',
  login_magic_subtitle: 'Check your inbox. The link is valid for 30 minutes.',
  login_magic_back: 'go back to login',
  login_magic_err_title: 'The link is broken or expired',
  login_magic_err_subtitle: `We couldn't log you in`,
  login_magic_err_back: 'go back and try again',

  // Logout
  goodbye: 'Goodbye!',
  logout_login_again: 'Go back to login',

  // Forgotten password form
  forgotten_password_title: 'Reset Your Password',
  forgotten_password_form_email: 'Email',
  forgotten_password_form_submit: 'Reset Password',
  forgotten_password_description: `Just enter your email address,
    and we'll send you a message with a link to reset your password.`,
  forgotten_password_form_password: 'New Password',
  forgotten_password_success: 'Password Reset Sent',
  forgotten_password_success_steps:
    'Check your inbox for an email with the next steps',
  forgotten_password_back_to_login: 'Go back to login',
  forgotten_password_error: `**We couldn't send a password reset email.**  
Maybe you mistyped your email address or tried to resend it multiple times in a short time.`,
  forgotten_password_link_not_valid: `**The password reset link is not valid.**  
Try again or write an email to [support@bonboarding.com](mailto:support@bonboarding.com)`,
  forgotten_password_try_again: 'Try again',
  forgotten_password_reset_success: 'You have successfully reset your password',
  forgotten_password_reset_success_steps:
    'You can now log in with your new password',
  forgotten_password_reset_go_to_login: 'Go to login',
  forgotten_password_unknown_error: `**We couldn't reset your password.**  
Try again or write an email to [support@bonboarding.com](mailto:support@bonboarding.com)`,

  // Verification
  verification_email_not_verified: 'Your email is not verified.',
  verification_email_sent: `We sent you a verification email.`,
  verification_email_sent_check_spam: `Don't forget to check it in your spam folder!`,
  verification_email_error: `Oops! Something didn't work out as expected.`,
  verification_email_error_try_again: 'Please try again.',
  verification_resend_email: 'Resend',

  email_verified_title: `All's done! We verified your email.`,
  email_verified_description: `You can continue setting up your onboarding processes`,
  email_verification_error_verified_title: 'The email is already verified',
  email_verification_error_title: `Ey! Something's not quite right`,
  email_verification_error_description: `We couldn't verify your email. Maybe the link has expired, please try again with a new verification code`,

  invite_verification_loading: 'We are verifying your invitation...',
  invite_verified_title: `You've been invited.`,
  invite_verified_description:
    'You can continue setting up your Bonboarding account',
  invite_verification_error_verified_title:
    'The invitation has already been accepted',
  invite_verification_error_title: `Ey! Something's not quite right`,
  invite_verification_error_description: `We couldn't accept your invitation. Maybe the link has expired, or you already created an account with your email address. Please try again with a new verification code`,

  // AppSumo
  appsumo_verification_loading: 'We are verifying your AppSumo purchase...',
  appsumo_verified_title: `All set!`,
  appsumo_verified_description:
    'You can continue setting up your Bonboarding account',
  appsumo_verification_error_title: `Ey! Something's not quite right`,
  appsumo_verification_error_description: `We couldn't accept your AppSumo token. Please contact us at info@bonboarding.com`,

  // Subscription
  trial_bar_period_title: 'Welcome to your trial period',
  trial_bar_period_sub_1: 'You have ',
  trial_bar_period_sub_2: ' days ',
  trial_bar_period_sub_2_left: ' days left ',
  trial_bar_period_sub_3: `to try Bonboarding's paid features.`,
  trial_bar_title: `You're using Bonboarding's free features`,
  trial_bar_sub:
    'for unlimited flows and steps, target your users and remove watermark.',
  trial_bar_btn_anytime: 'Upgrade anytime',
  trial_bar_btn_now: 'Upgrade now',

  // Register form
  register_title: '14-days free trial',
  register_subtitle: 'Full access to all features',
  register_feature_title_1: 'Quick and free sign-up',
  register_feature_description_1: `No credit card needed.
    It can’t get any easier!`,
  register_feature_title_2: 'Get started right away',
  register_feature_description_2: `It’s super simple to get started!
    Easily create stunning onboarding flows with the visual editor.`,
  register_feature_title_3: 'Forget about confused users',
  register_feature_description_3: `Help them with walktroughs and make
    sure they always know how to use the features of your product.`,
  register_form_title: 'Get started with your account',
  register_form_subtitle: 'Create your first product tour in just a minute!',
  register_form_email: 'Your business email',
  register_form_full_name: 'Your name',
  register_form_url: 'Your web app URL',
  register_form_url_subtitle:
    'Use the URL where you want to add the product tours',
  register_form_password: 'Password',
  register_form_terms_text_1: `I agree to Bonboarding's `,
  register_form_terms_link: 'Terms of Use',
  register_form_terms_text_2: ' and ',
  register_form_privacy_link: 'Privacy Policy',
  register_form_terms_text_3: '.',
  register_form_submit: 'Create your account',
  register_form_submit_comment: 'No credit card needed',
  register_footer_copy: '© Bonboarding · ',
  register_footer_privacy: 'Privacy',
  register_footer_terms: 'terms',
  register_back_to_login: 'Already have an account? Log in here.',

  password_hide: 'Hide',
  password_show: 'Show',

  // Validations
  validation_required: 'This field is required',
  validation_invalid_email: 'The email is invalid',
  validation_email_taken: 'This email is already taken. Try to log in instead!',
  validation_incorrect_password: 'The password is incorrect',
  validation_password_mismatch: `The password confirmation doesn't match the password`,
  validation_accept_terms: 'You must accept the terms and privacy policy',
  validation_url_min_length: 'The URL must be at least 8 characters long',
  validation_bonboarding_url_invalid: 'The URL cannot contain bonboarding.com',

  password_validator_description:
    'We care about security, so please choose a password that has the following:',
  password_validator_lowercase: 'One lowercase character',
  password_validator_uppercase: 'One uppercase character',
  password_validator_special_character: 'One number or special character',
  password_validator_min_length: '8 characters minimum',

  // Error pages
  error_404_page: `# Oops!

## It looks like a good old **404** situation.`,
  error_404_call_to_action: `Please don't break the internet, go back to safety:`,
  error_404_link_to_homepage: 'Homepage',
  error_404_link_to_dashboard: 'Dashboard',
  error_404_link_to_contact: 'Contact Us',

  // RecentActivity
  recent_user: 'User',
  recent_signup: 'Signup',
  recent_status: 'Status',
  recent_progress: 'Progress',
  recent_step: 'Step',
  recent_of: 'of',

  // SideBar
  sidebar_home: 'Home',
  sidebar_flows: 'Flows',
  sidebar_integration: 'Integration',
  sidebar_users: 'Users',
  sidebar_activity: 'Activity',
  sidebar_subscription: 'Subscription',
  sidebar_settings: 'Settings',
  sidebar_logout: 'Logout',
  sidebar_soon: 'Soon',
  sidebar_questions: 'Questions? Feedback?',
  sidebar_contact: 'Get in touch',
  sidebar_docs: 'Check out our guides',

  sidebar_free_title: 'Monthly Usage',
  sidebar_free_maus: ' MAUs)',
  sidebar_free_sub1: 'You are on the ',
  sidebar_free_sub2: 'free',
  sidebar_free_sub3: ' plan',
  sidebar_free_upgrade: 'Upgrade now',

  // Dashboard
  dashboard_hello: 'Hello ',
  dashboard_subtitle: 'Here’s your recent onboarding activity',
  dashboard_new_user: 'New Users',
  dashboard_onboarded: 'Onboarded',
  dashboard_dismissed: 'Dismissed',
  dashboard_average_steps: 'Average Steps',
  dashboard_average_steps_comment: '{count} of {total} total',
  dashboard_total_users: 'Total Users',
  dashboard_activity: 'Activity',
  dashboard_period: 'Period...',
  dashboard_flow: 'Flow...',
  dashboard_flow_all: 'All flows',
  period_week: '7 days',
  period_month: 'Month',
  period_year: 'Year',
  dashboard_chart_flows: 'New users onboarded',
  dashboard_empty_chart: 'Waiting for data',
  dashboard_empty_chart_subtitle:
    'once this flow has some activity, it will show up here',
  dashboard_stat_error: 'Error',
  dashboard_fetch_title: 'We could not fetch your data',
  dashboard_fetch_subtitle: 'Please try again later',
  dashboard_fetch_stats: 'Reload',

  // Flows
  flows_title: 'Flows',
  flows_subtitle: 'Because not only new users need guidence',
  flows_sub1:
    'This is the place where you can configure the onboarding flows of your app.',
  flows_sub2:
    'But why would you stop with onboarding? You have a killer new feature! Show it off to all your existing users!',
  flows_sub3:
    'New flows start as inactive, so your end-users won’t see them until you finished polishing them to perfection.',
  flows_add: 'Add new',
  flows_error_title: 'We could not fetch your flows',
  flows_error_subtitle: 'Please try again later',
  flows_error_reload: 'Reload',

  // FlowItem
  flowitem_steps: 'steps',
  flowitem_users: 'users',
  flowitem_avgtime: 'avg. time',
  flowitem_avgfinished: 'finished',
  flowitem_demo: 'Demo',
  flowitem_draft: 'Draft',
  flowitem_inactive: 'Inactive',
  flowitem_active: 'Active',
  flowitem_menu: 'Options',
  flowitemmenu_editor: 'Editor',
  flowitemmenu_editor_no_domain: 'You must configure your domain address first',
  flowitemmenu_edit: 'Edit',
  flowitemmenu_activate: 'Activate',
  flowitemmenu_deactivate: 'Deactivate',
  flowitemmenu_duplicate: 'Duplicate',
  flowitemmenu_targeting: 'Targeting',
  flowitemmenu_settings: 'Settings',
  flowitemmenu_remove: 'Remove',

  // ExtensionModal
  extension_modal_title: 'Install the Extension',
  extension_modal_subtitle1_1: "It's super easy to start",
  extension_modal_subtitle1_2: 'editing your flow!',
  extension_modal_subtitle2: 'Just install the ',
  extension_modal_subtitle3_firefox: 'Bonboarding Firefox Add-on,',
  extension_modal_subtitle3_chrome: 'Bonboarding Chrome Extension,',
  extension_modal_subtitle4: " and you're good to go!",
  extension_button_firefox: 'GO TO FIREFOX ADD-ONS',
  extension_button_chrome: 'GO TO CHROME WEB STORE',
  extension_modal_no_title: "Ejj... That's Not Going To Work",
  extension_modal_no_subtitle:
    'The Bonboarding editor is available on the desktop version of Google Chrome or Firefox.',
  extension_modal_no_subtitle2: 'Try again from one of those browsers.',

  // ExtensionInstalledModal
  extension_installed_modal_title: "You're Good to Go!",
  extension_installed_modal_sub1:
    'To always have Bonboarding Studio at your fingertips:',
  extension_installed_modal_first_step:
    'Click the puzzle icon near the URL bar',
  extension_installed_modal_second_step: 'Pin our extension for quick access',
  extension_installed_modal_ready:
    ' Are you ready to create some amazing product tours?',
  extension_installed_modal_button: 'Edit your flow',

  // FlowModals
  flow_remove_modal_title: 'Remove flow',
  flow_remove_modal_1: 'Are you sure you want to remove the following flow?',
  flow_remove_modal_2: 'This action cannot be undone.',
  flow_remove_modal_3:
    'All configuration, reference and historic user statistics will be removed as well.',
  flow_remove_modal_4: "Why don't you just deactivate it?",
  flow_remove_modal_cancel: 'Cancel',
  flow_remove_modal_ok: 'I understand, remove it',
  flow_new_modal_title: 'New flow',
  flow_edit_modal_title: 'Editing flow',
  flow_new_modal_1: 'Name of flow',
  flow_new_modal_save: 'Save',
  flow_new_modal_empty_name: 'The name field cannot be empty',
  flow_live_modal_title: 'This flow is live and active!',
  flow_live_modal_1:
    'Are you sure you want to make changes to it? It will be reflected right away to your users.',
  flow_live_modal_2:
    'We recommend that you create a copy of it and edit the new one instead.',
  flow_live_modal_duplicate: 'Edit a duplicated draft version',
  flow_live_modal_close: 'Close',
  flow_live_modal_edit: 'I understand, edit this flow anyways',
  flow_duplicate_modal_title: 'Duplicate flow',
  flow_duplicate_modal_1: `Do you want to keep the `,
  flow_duplicate_modal_2: `active `,
  flow_duplicate_modal_3: `status, or do you want to create a `,
  flow_duplicate_modal_4: `draft `,
  flow_duplicate_modal_5: `version instead?`,
  flow_duplicate_modal_draft: 'Do you want to duplicate this flow?',
  flow_duplicate_modal_cancel: 'Cancel',
  flow_duplicate_modal_yes: 'Yes, duplicate it',
  flow_duplicate_modal_yes_active1: 'Keep the ',
  flow_duplicate_modal_yes_active2: 'active ',
  flow_duplicate_modal_yes_active3: 'status',
  flow_duplicate_modal_yes_draft1: 'Duplicate a ',
  flow_duplicate_modal_yes_draft2: 'draft ',
  flow_duplicate_modal_yes_draft3: 'version',
  flow_activate_modal_title: 'Activate flow',
  flow_activate_modal_1: 'Are you ready to activate the following flow?',
  flow_activate_modal_2: 'It will be immediatelly available to your users.',
  flow_activate_modal_cancel: 'Cancel',
  flow_activate_modal_ok: 'Activate it',
  flow_cant_activate_modal_title: `The flow can't be activated`,
  flow_cant_activate_modal_1: `It's pointing to a development domain. Please change it in the editor.`,
  flow_cant_activate_modal_button: 'Open in editor',
  flow_deactivate_modal_title: 'Deactivate flow',
  flow_deactivate_modal_1: 'Are you ready to deactivate the following flow?',
  flow_deactivate_modal_2:
    "It will be immediatelly inactive and it won't appear to your users",
  flow_deactivate_modal_cancel: 'Cancel',
  flow_deactivate_modal_ok: 'Deactivate it',
  flow_freeuser_modal_title: 'Unlimited flows',
  flow_freeuser_modal_sub1:
    'You reached the limit of one active flow for the free plan.',
  flow_freeuser_modal_sub2: 'Upgrade now to our ',
  flow_freeuser_modal_sub3: 'growth',
  flow_freeuser_modal_sub4: ' plan to create new flows without limitations.',
  flow_freeuser_modal_upgrade: 'Upgrade now',
  flow_freeuser_modal_price: 'starting at only $99/month',
  flow_freeuser_targeting_modal_title: 'Target Users',
  flow_freeuser_targeting_modal_sub1:
    'Targeting your flows to specific user segments is available in our ',
  flow_freeuser_targeting_modal_sub2: 'growth',
  flow_freeuser_targeting_modal_sub3: ' plan.',

  // flow settings
  flow_settings_modal_title: 'Flow settings',
  flow_settings_modal_replay_title: 'Re-run after finish',
  flow_settings_modal_replay_sub:
    'If enabled, the flow will play again during the next session for the user',
  flow_settings_modal_restart_beginning_title: 'Restart from the beginning',
  flow_settings_modal_restart_beginning_sub:
    'If enabled, the flow will always start with the first step instead of what would come next',
  flow_settings_modal_default_domain_title: 'Default domain for editing',
  flow_settings_modal_default_domain_sub:
    'This does not affect the targeting rules, only where the flow is shown when you begin editing it',
  flow_settings_modal_save: 'Save',
  flow_settings_modal_cancel: 'Cancel',

  // flow targeting
  flow_targeting_modal_title: 'Target Users',
  flow_targeting_modal_subtitle1:
    'You can define which users should see this flow.',
  flow_targeting_modal_subtitle2:
    'If you don’t select any targeting rules, the flow will show up to all users.',
  flow_targeting_modal_users1: 'If you ',
  flow_targeting_modal_users2: 'define custom fields',
  flow_targeting_modal_users3:
    ' for your users, those values can also be used in targeting',
  flow_targeting_modal_new_users_title: 'New Users',
  flow_targeting_modal_new_users_text:
    'New users who haven’t been logged in the system before. (use together with sign up date, if you want to exclude users who already existed when the flow gets activated)',
  flow_targeting_modal_logged_in_users_title: 'Logged In Users',
  flow_targeting_modal_logged_in_users_text:
    'Users who are currently logged in to your system and can be identified.',
  flow_targeting_modal_session_count_title: 'Session Count',
  flow_targeting_modal_session_count_text:
    'The number the user visited your site',
  flow_targeting_modal_last_seen_title: 'Last Seen',
  flow_targeting_modal_last_seen_text:
    'The date of the last time the user visited your site.',
  flow_targeting_modal_first_seen_title: 'First Seen',
  flow_targeting_modal_first_seen_text:
    'The date of the last time the user visited your site.',
  flow_targeting_modal_total_duration_title: 'Total Duration',
  flow_targeting_modal_total_duration_text:
    'The total time that the user spent on your site. (in minutes)',
  flow_targeting_modal_current_session_duration_title:
    'Current Session Duration',
  flow_targeting_modal_current_session_duration_text:
    'The time the user spent on your site in the current session. (in minutes)',
  flow_targeting_modal_country_title: 'Country',
  flow_targeting_modal_country_text: `The country, based on the IP address of the user's last session.`,
  flow_targeting_modal_device_title: 'Device',
  flow_targeting_modal_device_text: `The device of the user's current session.`,
  flow_targeting_modal_domain_title: 'Domain',
  flow_targeting_modal_domain_text: `The domain of the visited page.`,
  flow_targeting_modal_bookmark_title: 'Bookmarked Page',
  flow_targeting_modal_bookmark_text:
    'A bookmarked URL of a page on your site.',
  flow_targeting_modal_bookmark_disabled_reason: `You can't use this targeting rule because there's no bookmarked URL. You can bookmark pages within the editor.`,
  flow_targeting_modal_first_name_title: 'First Name',
  flow_targeting_modal_first_name_text: `The user's first name.`,
  flow_targeting_modal_last_name_title: 'Last Name',
  flow_targeting_modal_last_name_text: `The user's last name.`,
  flow_targeting_modal_email_title: 'Email',
  flow_targeting_modal_email_text: `The user's email.`,
  flow_targeting_modal_signup_date_title: 'Sign-Up Date',
  flow_targeting_modal_signup_date_text: 'The registration date of the user.',
  flow_targeting_modal_custom_text: `Custom user field of {type}. Default: {default}.`,
  flow_targeting_modal_flow_title: 'Finished flow',
  flow_targeting_modal_flow_text:
    'Target users who already finished with another flow.',
  flow_targeting_modal_flow_disabled_reason: `You can't use this targeting rule because there's no other flow that could be used as criteria.`,

  flow_targeting_and: 'and',
  flow_targeting_or: 'or',
  flow_targeting_add_filter: 'Add filter',
  flow_targeting_add_new_filter: 'Add new filter',
  flow_targeting_add_or_filter: 'Add "or" filter',
  flow_targeting_add_new_or_filter: 'Add new "or" filter',

  condition_select: 'Select...',
  condition_equals: 'Equals',
  condition_not_equals: 'Does not equal',
  condition_less_than: 'Less than',
  condition_less_than_or_equal: 'Less than or equal to',
  condition_greater_than: 'Greater than',
  condition_greater_than_or_equal: 'Greater than or equal to',
  condition_true: 'True',
  condition_false: 'False',
  condition_is: 'Is',
  condition_is_not: 'Is not',
  condition_same_day: 'Same day',
  condition_not_same_day: 'Not same day',
  condition_before: 'Before',
  condition_before_or_same_day: 'Before or same day',
  condition_after: 'After',
  condition_after_or_same_day: 'After or same day',
  condition_new_user: 'New User',
  condition_not_new_user: 'Not new user',
  condition_logged_in_user: 'Logged In User',
  condition_not_logged_in_user: 'Not logged in user',
  condition_contains: 'Contains',
  condition_not_contains: 'Does not contain',

  flow_targeting_confirm_modal_title: 'Save new rules',
  flow_targeting_confirm_modal_subtitle:
    'This flow is currently active. Any changes made to it will take effect immediately.',
  flow_targeting_confirm_modal_question:
    'Are you sure you want to change the targeting rules?',
  flow_targeting_confirm_modal_cancel: 'Cancel',
  flow_targeting_confirm_modal_update: 'Yes, update the rules',

  flow_added: 'YAY! You just added a new flow',
  flow_added_message: `It's not active yet. Go and configure the steps.`,
  flow_updated: 'Done! You updated the flow',
  flow_add_error: 'There was an error while creating your flow',
  flow_update_error: 'There was an error while updating your flow',
  flow_duplicated: 'Done! You duplicated the flow',
  flow_duplicated_error: 'There was an error while duplicating your flow',
  flow_activated: 'Congrats! You just activated the flow',
  flow_activated_message: `It's visible to the selected audience`,
  flow_activated_error: 'There was an error while activating your flow',
  flow_deactivated: 'You deactivated the flow',
  flow_deactivated_message: `It's not visible to anyone`,
  flow_deactivated_error: 'There was an error while deactivating your flow',
  flow_removed: 'You just removed the flow',
  flow_removed_error: 'There was an error while removing your flow',
  flow_targeting_updated: 'Done! You added the rules',
  flow_targeting_update_error: 'There was an error while adding your rules',

  // Integration
  integration_title: 'Integration',
  integration_subtitle: `It couldn't get any easier`,
  integration_sub1: `Getting started is really simple.`,
  integration_sub2: `First, you need to tell us the domain name of your webapp:`,
  integration_sub3: `And copy this line of code to all your HTML files, **just before the closing** \`</head>\` **tag**.`,
  integration_sub4: `That's it! Now you can get started creating your onboarding flows.`,
  integration_additional_info_title: 'Passing Additional Information',
  integration_additional_info: `You must pass the API key every time you initialize Bonboarding.
    But you can also include other data about your logged-in user. These values can be then used
    to target your flows or add as placeholders in your steps.`,
  integration_additional_info_link: `There are four default user fields:
  - \`uniqueID\` (the user's ID in your platform)
  - \`firstName\`
  - \`lastName\`
  - \`email\`
  
  But you can add your own custom fields in the Users page:`,
  integration_edit_user_fields_button: 'Visit the Users Page',
  integration_additional_info_change_title: `What happens if a user logs in or their data changes?`,
  integration_additional_info_change: `Simple. Just change the config object. No need to trigger anything else, Bonboarding will detect your changes automatically.`,
  integration_additional_info_change_1: `Initialize without any specific user data:`,
  integration_additional_info_change_2: `Once the user is determined update the config:`,
  integration_additional_info_change_3: `Update the config if the user's data changes:`,
  integration_domain: `Your webapp's domain name`,
  integration_domain_placeholder: 'https://',
  integration_domain_save: 'Save changes',
  integration_additional_domains_title: 'Additional domains',
  integration_additional_domains_description: `You can define multiple domains (or subdomains), so you can run your Bonboarding flows on them, or test them locally before launching on your live site.
  
  These domains **must** include the protocol (\`http://\` or \`https://\`) and port numbers as well, if applicable.
  
  You don't need to explicitly add all domains and subdomains, but make sure to *mark the development domains* so we don't count the user activity on those.`,
  integration_wildcard_domain_title: 'Wildcard in domains',
  integration_wildcard_domain: `You can use the star symbol (\`*\`) as a wildcard for subdomains, but it must be used in accordance with the following rules in order to properly function:  
- The protocol of the URL **must** be \`http:\` or \`https:\`. \`com.example.app://*.example.com\` will not work.
- The wildcard **must** be located in a subdomain within the hostname component. \`https://*.com\` will not work.
- The URL **must not** contain more than one wildcard. \`https://*.*.example.com\` will not work.`,
  integration_dev_domain_placeholder: 'http://localhost:3000',
  integration_domain_dev: 'DEV',
  integration_domain_dev_description: `We don't track your users on your development domains. They can be used for testing your flows locally.`,
  integration_domain_no_delete_active: `You can't delete this domain, because it's used in some of your active flows:`,
  integration_additional_domain_add: 'Add new domain',
  integration_domain_updated: 'We updated your domains',
  integration_domain_update_error:
    'There was an error while updating your domains',
  integration_domain_belongs_to_active_flow_1: `Be careful!`,
  integration_domain_belongs_to_active_flow_2: `This domain is used in some of your active flows:`,
  integration_domain_bonboarding: 'You cannot add bonboarding.com as a domain',

  integration_wordpress_title: 'WordPress Plugin Integration',
  integration_wordpress_description:
    'Integrating Bonboarding with your WordPress site is straightforward with our WordPress plugin.',
  integration_wordpress_description_2: 'Here’s how you can do it:',
  integration_step_1_title: 'Download the Plugin:',
  integration_step_1_button: 'Bonboarding WordPress Plugin',
  integration_step_1_button_title:
    'Download the Bonboarding WordPress plugin as a zip file.',
  integration_step_1_description: `
- Click the **Download** button.`,
  integration_step_2_title: 'Install the Plugin:',
  integration_step_2_description: `

- Log in to your WordPress dashboard.
- Navigate to **Plugins > Add New > Upload Plugin**.
- Click **Choose File** and select the Bonboarding plugin zip file you downloaded.
- Click **Install Now**. Once the plugin is installed, click **Activate**.
`,
  integration_step_3_title: 'Configure the Plugin:',
  integration_step_3_description: `

- In your WordPress dashboard, navigate to **Settings > Bonboarding** on the left-hand menu.
- Enter your **Bonboarding API key**.
- Choose where to play your product tours.
- Click **Save Changes**.
`,
  integration_step_4_title: 'Create Product Tours:',
  integration_step_4_description:
    'With the plugin activated and configured, you can now start creating onboarding flows and product tours from your Bonboarding dashboard.',

  // Users
  users_title: 'Users',
  users_subtitle: 'Guide them! Teach them! Help them!',
  users_sub1: `Your users are the center of the product tours.`,
  users_sub2: `Find out everything about them, define what data should be collected, and see how they adapt to your product.`,
  users_coll_title: 'Collected data',
  users_coll_subtitle:
    'No two users are the same. Target them based on custom criteria',
  users_coll_sub1: `You can define what data we should collect about your users. This can be used to further target them in your flows. It can be anything from their name and email to the date of their last purchase, or their preferred language.`,
  users_available_field: 'Available fields',
  user_fields_error_title: 'We could not fetch your available fields',
  user_fields_error_subtitle: 'Please try again later',
  user_fields_error_reload: 'Reload',
  users_new: 'Add new',
  user_field_default: 'default:',
  user_field_locked: 'This default field cannot be altered',
  user_field_empty: 'empty',
  user_field_empty_string: 'empty string',
  users_quick_integration: 'Quick Integration Instructions',
  users_integration_sub1:
    'Add the following code to your site to let us know about the values that belong to the user:',
  users_integration_sub2: `(replace the **bold** values with the current user’s attributes)
  

Every field name is converted to **camelCase**, so the first letter of each word is capitalized, 
except for the first word.

You can see all your fields in the example above.`,
  users_integration_help: 'Do you need more help?',
  users_integration_button: 'Visit the Integrations page',

  users_usage: 'Usage',
  users_upgrade: 'Upgrade',
  users_maus: ' Monthly Active Users',
  users_usage_sub1: `that's `,
  users_usage_sub2: ' usage in your current billing period',
  users_usage_bracket1: '(your subscription includes ',
  users_usage_bracket2: ' MAUs, it resets on ',
  users_usage_bracket3: ')',
  users_usage_trial: 'You have unlimited MAUs in your trial',
  users_usage_unlimited: 'You have unlimited MAUs',

  // User new and edit modal
  user_fields_new_modal_title: 'Add new field',
  user_fields_edit_modal_title: 'Edit field',
  user_fields_new_modal_1: 'Name of field',
  user_fields_modal_type: 'Type',
  user_fields_modal_default: 'Default value',
  user_fields_modal_empty_name: 'The name field cannot be empty',
  user_fields_modal_empty: 'Empty',
  user_fields_modal_true: 'True',
  user_fields_modal_false: 'False',
  user_fields_modal_text_title: 'Text: ',
  user_fields_modal_text:
    'use this field type for any text value, like country, name, language, username, etc.',
  user_fields_modal_text2:
    'Bonboarding will use this value if you don’t specify one for a user; or for users that already exist in our database.',
  user_fields_modal_number_title: 'Number: ',
  user_fields_modal_number:
    'use this field type for any numeric value, such as age, prices etc',
  user_fields_modal_number2:
    'Bonboarding will use this value if you don’t specify one for a user; or for users that already exist in our database.',
  users_calendar: 'Calendar',
  user_fields_modal_date_title: 'Date: ',
  user_fields_modal_date:
    'Date: use this field type for any date value, like date of birth, last payment, sign up date, etc.',
  user_fields_modal_date2:
    'Bonboarding will use this value if you don’t specify one for a user; or for users that already exist in our database.',
  user_fields_modal_date_current: 'Current date',
  user_fields_modal_switch_title: 'Switch: ',
  user_fields_modal_switch:
    'Switch: use this field type for any true/false value, such as checking if user is subcriber, user accepted the terms, etc.',
  user_fields_modal_switch2:
    'Bonboarding will use this value if you don’t specify one for a user; or for users that already exist in our database.',
  user_fields_modal_create: 'Create',
  user_fields_modal_save: 'Save',

  user_field_added: 'YAY! You just added a new user field',
  user_field_updated: 'Done! You updated the user field',
  user_field_add_error: 'There was an error while creating your user field',
  user_field_update_error: 'There was an error while updating your user field',

  // User remove modal
  user_field_remove_modal_title: 'Remove user field',
  user_field_remove_modal_1: 'Are you sure you want to remove this field?',
  user_field_remove_modal_2:
    'Its value will be removed from all user, and from any targeting criteria where it was used.',
  user_field_remove_modal_cancel: 'Cancel',
  user_field_remove_modal_ok: 'I understand, remove it',
  user_field_removed: 'You just removed the user field',
  user_field_removed_error: 'There was an error while removing your user field',

  // User export
  users_export_title: 'Export Users',
  users_export_description: 'Export all your user data to a CSV file.',
  users_export_comment: 'It might take 1-2 minutes to download the file.',
  users_export_button: 'Download CSV',
  users_export_time_range_placeholder: 'Select time range',
  users_export_time_range_current: 'Current Billing Period',
  users_export_time_range_30days: 'Last 30 Days',
  users_export_time_range_halfyear: 'Last Half Year',
  users_export_time_range_year: 'Last Year',
  users_export_time_range_alltime: 'All Time',
  users_export_success: 'CSV download started',
  users_export_error: 'Error downloading CSV',

  // Activity
  activity_title: 'Activity',
  activity_subtitle: 'All of your users activity in one place',
  activity_filter_filter: 'filter',
  activity_flow_all: 'All flows',
  activity_flow: 'Flow...',
  activity_to: 'to',
  activity_filter_now: 'now',
  activity_filter_now_live: 'Live',
  activity_calendar: 'Calendar',
  activity_filter_reset: 'Reset',
  activity_filter_search: 'Search',
  activity_filter_advanced: 'advanced search',
  activity_filter_add_filter: 'Add filter',
  activity_filter_dropdown: 'Filter...',
  activity_advanced_type: 'Type',
  activity_events_total: 'Total',
  activity_events_events: ' events',
  activity_events_waiting: 'Waiting for the first event',
  activity_events_none: 'There are no events for this criteria',
  activity_event_header_event: 'Event',
  activity_event_header_user: 'User',
  activity_event_header_step: 'Step, flow',
  activity_event_header_date: 'Took place on',

  // EventItem
  eventitem_menu: 'Options',
  eventitem_details: 'Details',
  eventitem_view_user: 'View User',
  eventitem_similar: 'Show similar',
  eventitem_step: 'Step',
  eventitem_viewed_step: 'viewed step',
  eventitem_average_time: 'avarage time for this step',
  eventitem_total_in_flow: 'total time in flow',
  eventitem_device: 'device:',
  eventitem_device_mobile: 'mobile',
  eventitem_device_tablet: 'tablet',
  eventitem_device_desktop: 'desktop',
  eventitem_device_bot: 'bot',
  eventitem_os: 'os:',
  eventitem_browser: 'browser:',
  eventitem_country: 'country:',
  eventitem_higlight: '(highlight)',
  eventitem_popup: '(popup)',
  eventitem_anonymous: 'Anonymous',
  eventitem_preview: 'preview',
  eventitem_preview_tooltip_1: 'This event happened in preview ',
  eventitem_preview_tooltip_2: 'mode, not during a live run.',
  // event item - event types
  eventitem_event_first_seen: 'First Seen',
  eventitem_event_start_session: 'Start Session',
  eventitem_event_end_session: 'End Session',
  eventitem_event_open_page: 'Open Page',
  eventitem_event_leave_page: 'Leave Page',
  eventitem_event_start_flow: 'Start Flow',
  eventitem_event_finish_flow: 'Finish Flow',
  eventitem_event_skip_flow: 'Skip Flow',
  eventitem_event_show_step: 'Show Step',
  eventitem_event_end_step: 'End Step',
  eventitem_event_close_step: 'Close Step',
  eventitem_event_end_step_with_skip: 'End Step With Skip',
  eventitem_event_end_step_expire: 'End Step (Expire)',
  eventitem_event_button_click: 'Button Clicked',
  eventitem_event_next_button_click: 'to next step',
  eventitem_event_skip_button_click: 'to skip flow',
  eventitem_event_specific_button_click: 'to specific step',
  eventitem_event_close_button_click: 'to close flow',
  eventitem_event_finish_button_click: 'to finish flow',
  eventitem_event_page_button_click: 'to go to page',
  eventitem_event_pagination_to_next_step: 'Pagination To Next Step',
  eventitem_event_pagination_to_specific_page: 'Pagination To Specific Page',

  // User Modal
  user_modal_latest_activity: 'Latest activity',
  user_modal_sessions: 'Sessions:',
  user_modal_total: 'Total duration:',
  user_modal_last: 'Last session:',
  user_modal_device: 'Device:',
  user_modal_os: 'OS:',
  user_modal_browser: 'Browser:',
  user_modal_country: 'Country:',
  user_modal_events: 'Events',
  user_modal_close: 'Close',
  user_modal_slash_step: ' / step ',

  // Subscription
  subscription_title: 'Subscription',
  subscription_subtitle: 'Manage your subscription and invoices',
  subscription_card_title: `You're currently on our`,
  subscription_card_daytrial: '14-day trial',
  subscription_card_money_back_guarantee: 'with a 60-day money back guarantee',
  subscription_card_period: 'period',
  subscription_card_free: 'free ',
  subscription_growth_membership: 'growth',
  subscription_lifetime_membership: 'lifetime',
  subscription_card_membership: 'membership',
  subscription_card_unlimited: 'Unlimited Plan',
  subscription_card_trial_title: 'Hope you like it!',
  subscription_card_trial_ends: `You're trial period ends on `,
  subscription_card_unlimited_ends: `You're unlimited plan ends on `,
  subscription_card_trial_subtitle:
    'After that, you will continue with our free membership.',
  subscription_card_upgrade: 'Upgrade now',

  subscription_card_appsumo_mau: ' monthly active users',
  subscription_card_appsumo_1: 'Unlimited Product Tours & Steps',
  subscription_card_appsumo_2: 'User Segmentation',
  subscription_card_appsumo_3: 'Full Control Over the look',
  subscription_card_appsumo_4: 'User Level Tracking',
  subscription_card_appsumo_5: 'Actionable Insights',
  subscription_card_appsumo_6: 'Removal of branding',
  subscription_card_appsumo_7: 'Integrations',
  subscription_card_appsumo_8: 'Role Based Access',

  subscription_why: `Why it's worth upgrading?`,
  subscription_why_1: 'unlimited flows',
  subscription_why_2: 'unlimited steps',
  subscription_why_3: 'target flows to specific user',
  subscription_why_4: 'no watermark',
  subscription_why_5: 'detailed analytics',
  subscription_why_6: 'and much more!',
  subscription_card_thanks: 'Thank you!',
  subscription_card_billed: `You're being billed `,
  subscription_card_curr_period: '(current period:',
  subscription_card_mau: 'Your monthly active user limit is ',
  subscription_card_next_invoice: 'Next invoice: ',
  subscription_card_next_invoice_charged: '(charged on ',
  subscription_card_card_ending: ' - card ending ',
  subscription_card_update_payment: 'Update payment method',
  subscription_card_canceled: 'Your subscription has been canceled.',
  subscription_card_canceled_2: 'It is active until {date}',
  subscription_cancel_membership: 'Cancel membership',
  subscription_previous_invoices: 'Previous Invoices',
  subscription_invoice_monthly: '(monthly)',
  subscription_invoice_yearly: '(yearly)',
  subscription_unlimited: ' Unlimited ',
  subscription_mau: ' MAU ',
  subscription_paid: ' PAID',
  subscription_paid_on: '(paid on ',
  subscription_due: ' DUE',
  subscription_pay_now: 'Pay now',
  subscription_no_invoices: 'You don’t have any invoices.',
  subscription_invoice_payment_error:
    'There was an error while paying your invoice',

  subscription_success_modal_title: 'Payment successful',
  subscription_update_success_modal_title: 'Payment method updated',
  subscription_success_modal_sub1: 'Thanks for subscribing to Bonboarding!',
  subscription_success_modal_sub2:
    'You can now continue using all of our features!',
  subscription_success_modal_sub3: 'So what are you waiting for?',
  subscription_success_modal_sub4: 'Go, create some amazing product tours!',
  subscription_success_modal_close: 'Close',

  payment_modal_title: 'Subscribe',
  payment_modal_cancel: 'Cancel',
  payment_modal_update_payment_title: 'Update Payment Method',
  payment_modal_update_payment: 'Update Payment',
  payment_modal_subscribe: 'Subscribe',

  payment_method_error: 'There was an error with your payment method',

  cancel_subscription_modal_title: 'Cancel subscription',
  cancel_subscription_modal_sub1: 'We’d hate to see you go, but that’s life.',
  cancel_subscription_modal_sub2:
    'If you have a moment, please leave us a note why you decided to cancel your subscription, so we can improve our service.',
  cancel_subscription_modal_sub3:
    'If you cancel now, you will still be able to use all the features of your subscription until ',
  cancel_subscription_modal_sub4:
    'To cancel your subscription, please enter your password here:',
  cancel_subscription_modal_password: 'Password',
  cancel_subscription_modal_close_btn: 'Close window',
  cancel_subscription_modal_cancel_btn: 'Cancel my subscription',
  cancel_subscription_modal_success: 'Subscription cancelled',
  cancel_subscription_modal_error: 'The password was incorrect',

  upgrade_modal_title: 'Upgrade subscription',
  upgrade_modal_sub:
    'Tell us your needs and we’ll get back to you within 24 hours',
  upgrade_modal_info_title: 'Are you running out of your MAUs?',
  upgrade_modal_info_sub1:
    'Don’t worry, we won’t block them, you can continue using the',
  upgrade_modal_info_sub2:
    'tool without limitations or any other interruptions',
  upgrade_modal_cancel: 'Cancel',
  upgrade_modal_send: 'Send upgrade request',
  upgrade_modal_success: 'We received your upgrade request',

  // Subscription modal
  dropdown_maus: ' MAUs',
  dropdown_monthly: 'monthly',
  dropdown_yearly: 'yearly',
  subscription_modal_package: 'Package',
  subscription_modal_mau_explain:
    'how many unique users are using your app each month',
  subscription_modal_billing_period: 'Billing period',
  subscription_modal_per_month: '/month)',
  subscription_modal_due_today: 'Total due today',
  subscription_modal_billing_details: 'Billing Details',
  subscription_modal_company_name: 'Company Name',
  subscription_modal_line1: 'Address - Line 1',
  subscription_modal_line2: 'Address - Line 2',
  subscription_modal_city: 'City',
  subscription_modal_zip: 'Zip code',
  subscription_modal_country: 'Country',
  subscription_modal_vat: 'Tax number',
  subscription_modal_card: 'Card',
  subscription_modal_cancel: 'Cancel',
  subscription_modal_subscribe: 'Subscribe',

  // Settings
  settings_title: 'Settings',
  settings_subtitle: 'Everything about you and your company',
  settings_personal_settings: 'Personal Settings',
  settings_security_settings: 'Security',
  settings_company_settings: 'Company',
  settings_integrations_settings: 'Integrations',
  settings_users_settings: 'Users',
  settings_name: 'Name',
  settings_email: 'Email',
  settings_language: 'Language',
  settings_company_name: 'Company name',
  settings_company_line1: 'Address - Line 1',
  settings_company_line2: 'Address - Line 2',
  settings_company_city: 'City',
  settings_company_postal_code: 'Postal code',
  settings_company_country: 'Country',
  settings_company_website: 'Website',
  settings_company_email: 'Email',
  settings_company_vat_number: 'Tax number',
  settings_update_password: 'Update Password',
  settings_update: 'Update',
  settings_update_personal: 'Update personal details',
  settings_update_company: 'Update company',

  settings_personal_updated: 'You updated your personal settings',
  settings_personal_update_error:
    'There was an error while updating your personal settings',
  settings_company_updated: 'You updated the company settings',
  settings_company_update_error:
    'There was an error while updating the company settings',
  settings_close_password: 'Cancel',
  settings_change_password: 'Update password',
  settings_set_password: 'Set password',
  settings_current_password: 'Current password',
  settings_new_password: 'New password',
  settings_confirm_password: 'Confirm password',
  settings_password_updated: 'You updated your password',
  settings_password_update_error:
    'There was an error while updating your password',
  settings_update_profile: 'Update profile',
  settings_update_profile_name: 'Your name',
  settings_update_profile_app_domain: `Your company's URL`,
  settings_update_profile_app_domain_description:
    'This field is required so we know what page to open when you start editing your product tours',
  settings_update_profile_password: 'Password',
  settings_update_profile_save: 'Save',
  settings_profile_updated: 'You updated your profile',
  settings_profile_update_error:
    'There was an error while updating your profile',

  // User settings
  users_add: 'Invite new user',
  users_error_title: 'We could not fetch your users',
  users_error_subtitle: 'Please try again later',
  users_error_reload: 'Reload',
  users_pending_invites: 'Pending invitations',
  users_list_title: 'Users',
  user_role_bonboarding_admin: 'Bonboarding Admin',
  user_role_admin: 'Admin',
  user_role_editor: 'Editor',
  user_role_read_only: 'Read-only',
  useritem_menu: 'Menu',
  useritem_remove: 'Remove',
  useritem_change_role: 'Change role',

  user_invite_modal_title: 'Invite a new user',
  user_invite_modal_email_label: 'Email of new user',
  user_invite_modal_role_label: 'Role',
  user_invite_modal_save: 'Send invitation',
  user_added: 'YAY! The invitation has been sent',
  user_added_message: `It's already in your new user's inbox`,
  user_add_error: 'There was an error while sending the invitation',

  user_remove_modal_title: 'Remove user',
  user_remove_modal_1: 'Are you sure you want to remove the following user?',
  user_remove_modal_2: 'This action cannot be undone.',
  user_remove_modal_3:
    'The user will not be able to log back into Bonboarding.',
  user_remove_modal_cancel: 'Cancel',
  user_remove_modal_ok: 'I understand, remove the user',
  user_removed: 'You just removed the user',
  user_removed_error: 'There was an error while removing your user',

  user_change_role_modal_title: 'Change user role',
  user_change_role_modal_role_label: 'Role',
  user_change_role_modal_warning:
    'Changing the role will affect the user immediately',
  user_change_role_modal_save: 'Update',
  user_role_updated: 'Done! You updated the user role',
  user_role_updated_message: 'They will see the changes right away',
  user_role_update_error: 'There was an error while updating the user role',

  // Integrations
  settings_integrations_connected: 'Connected',
  settings_integrations_connect: 'Connect',
  settings_available_integrations: 'Available integrations',
  settings_slack_title: 'Slack',
  settings_slack_description: `Connect Bonboarding to your Slack workspace to receive notifications about your users' activity.`,
  settings_slack_connected_description: `You can see your users' activity in the #bonboarding-tours channel.`,
  settings_connect_slack_button: 'Connect',
  settings_zapier_title: 'Zapier',
  settings_zapier_description: `Automate your workflows with Bonboarding and Zapier. Connect Bonboarding to over 1,000 apps and start creating zaps.`,
  settings_zapier_connected_description: ``,
  settings_connect_zapier_button: 'Connect',

  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],

  // Websummit
  websummit_discount: '50% off first 3 months',
  websummit_button: `Let's go`,
};

export default en;
