/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showAdminDashboard, setActiveMenu } from '../actions/layout';

const Route = ({
  guarded,
  guest,
  fallback,
  active,
  guestRoute,
  guardedRoute,
  ...props
}) => {
  const authenticated = useSelector(state => state.auth.authenticated);
  const loggingOut = useSelector(state => state.auth.loggingOut);
  const dispatch = useDispatch();

  // Guarded and guest routes
  useEffect(() => {
    if (guarded) {
      dispatch(showAdminDashboard(true));
      dispatch(setActiveMenu(active));
    } else {
      dispatch(showAdminDashboard(false));
    }
  }, [props.path]);

  // Routes that redirect based on authentication
  if (guardedRoute && authenticated) {
    return <Redirect to={guardedRoute} />;
  }
  if (guestRoute && !authenticated) {
    return <Redirect to={guestRoute} />;
  }

  // Generic routes
  if (!guarded && !guest) {
    dispatch(showAdminDashboard(false));
    return <ReactRoute {...props} />;
  }

  if (
    !loggingOut &&
    ((guarded && !authenticated) || (guest && authenticated))
  ) {
    return <Redirect to={fallback} />;
  }

  return <ReactRoute {...props} />;
};

Route.propTypes = {
  guarded: PropTypes.bool,
  guest: PropTypes.bool,
  fallback: PropTypes.string,
  guestRoute: PropTypes.string,
  guardedRoute: PropTypes.string,
};

Route.defaultProps = {
  guarded: false,
  guest: false,
  fallback: '/login',
  guestRoute: null,
  guardedRoute: null,
};

export default Route;
