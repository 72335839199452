export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  let newCookie = `${cname}=${cvalue};${expires};`;
  if (process.env.REACT_APP_COOKIE_DOMAIN) {
    newCookie += `domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
  }
  newCookie += 'path=/';
  document.cookie = newCookie;
};

export const getCookie = cname => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = cname => {
  setCookie(cname, null, -1);
};
