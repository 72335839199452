import { Crisp } from 'crisp-sdk-web';

const chat = ({ name, email }) => {
  Crisp.configure('d8698ee2-2e09-4b88-a477-1030e90af3df');
  Crisp.chat.hide();

  if (name) {
    Crisp.user.setNickname(name);
  }

  if (email) {
    Crisp.user.setEmail(email);
  }

  Crisp.chat.onChatClosed(() => {
    Crisp.chat.hide();
  });
};

export default chat;

export const openChat = () => {
  Crisp.chat.show();
  Crisp.chat.open();
};
