/* eslint-disable camelcase */
import axios from 'axios';

// fetch activities
export function fetchActivity(params) {
  return dispatch => {
    const merged = Object.assign(...params);
    dispatch({ type: 'ACTIVITY_FETCHING', params: merged });
    axios(`/activity`, { params: merged })
      .then(response => {
        dispatch({
          type: 'ACTIVITY_FETCHED',
          data: response.data || { total: 0, data: [] },
        });
      })
      .catch(() => {
        dispatch({
          type: 'ACTIVITY_FETCHED',
          data: { total: 0, data: [] },
        });
      });
  };
}

// fetch more activities
export function fetchMoreData() {
  return (dispatch, getState) => {
    const state = getState();
    const merged = state.activity.params;
    merged.last_event_id =
      state.activity.list?.length > 0
        ? state.activity.list.slice(-1)[0].id.toString()
        : null;

    if (!merged.last_event_id) {
      return;
    }

    delete merged.first_event_id;
    if (state.activity.morelistLoading) {
      return;
    }
    dispatch({ type: 'MORE_ACTIVITY_FETCHING' });
    axios(`/activity`, { params: merged }).then(response => {
      if (response.data.data.length !== 0) {
        dispatch({ type: 'MORE_ACTIVITY_FETCHED', data: response.data });
      }
    });
  };
}

// fetch latest activities
export function fetchLatestData(firstevent) {
  return (dispatch, getState) => {
    const state = getState();
    const merged = state.activity.params;
    merged.first_event_id = firstevent.toString();
    delete merged.last_event_id;
    axios(`/activity`, { params: merged }).then(response => {
      if (response.data.data.length !== 0) {
        dispatch({ type: 'LATEST_ACTIVITY_FETCHED', data: response.data });
      }
    });
  };
}

// fetch details for specific event
export function fetchDetails(id) {
  return dispatch => {
    dispatch({ type: 'DETAILS_FETCHING', data: id });
    axios(`/activity/${id}`).then(response => {
      dispatch({
        type: 'DETAILS_FETCHED',
        data: response.data,
      });
    });
  };
}

// fetch filters
export function fetchFilters() {
  return dispatch => {
    dispatch({ type: 'ACTIVITY_FILTERS_FETCHING' });
    axios(`/activity/filters`).then(response => {
      dispatch({ type: 'ACTIVITY_FILTERS_FETCHED', data: response.data });
    });
  };
}

// fetch user
export function fetchUser(id) {
  return dispatch => {
    dispatch({ type: 'USER_FETCHING' });
    axios(`/activity/user/${id}`).then(response => {
      dispatch({
        type: 'USER_FETCHED',
        data: response.data,
      });
    });
  };
}

// show similar
export function showSimilar(event) {
  return { type: 'SHOW_SIMILAR', event };
}

// delete similar from redux
export function notshowSimilar() {
  return { type: 'DONT_SHOW_SIMILAR' };
}

export function activityListLoadedFalse() {
  return { type: 'LOADED_FALSE' };
}
