import React from 'react';
import { Link } from 'react-router-dom';
import localized from '../../i18n';

const Logout = () => (
  <div
    className={`w-screen h-screen bg-muted fixed top-0 left-0
        flex flex-col items-center justify-center -z-10`}
  >
    <h2 className="text-secondary text-3xl mb-8">{localized.goodbye}</h2>
    <Link to="/login" className="btn btn--md btn--secondary mx-auto">
      {localized.logout_login_again}
    </Link>
  </div>
);

export default Logout;
