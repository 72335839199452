import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';
import { getCookie, deleteCookie } from '../../utils/cookies';

import { notAuthenticated, loginSucceeded } from '../../actions/auth';

const Startup = () => {
  const dispatch = useDispatch();

  // Check if token exists in localstorage
  const token = getCookie('bb_token');

  // If there is no token, the user is not authenticated
  if (!token) {
    dispatch(notAuthenticated());
  }

  // Attempt to see if user is authenticated
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const [{ data, loading, error }] = useAxios('/users/me');

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: process.env.REACT_APP_DEBUG === 'true',
      });
    }

    if (!loading) {
      if (!error) {
        dispatch(loginSucceeded({ user: data }));
      } else {
        axios.defaults.headers.common.Authorization = null;
        deleteCookie('bb_token');
        dispatch(notAuthenticated());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return null;
};

export default Startup;
