import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import EmailNotVerified from '../EmailNotVerified';
import TrialInfoBar from '../TrialInfoBar';
import UserGuideFunctions from '../UserGuideFunctions';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const adminDashboardVisible = useSelector(
    state => state.layout.adminDashboardVisible,
  );

  const user = useSelector(state => state.auth.user);

  if (adminDashboardVisible) {
    return (
      <div
        onScroll={event => {
          if (
            event.target.scrollHeight - event.target.scrollTop ===
            event.target.clientHeight
          ) {
            dispatch({ type: 'SCROLL_BOTTOM_REACHED' });
          }
        }}
        className="flex flex-row bg-background w-screen h-screen overflow-x-hidden"
      >
        <Sidebar />
        <div className="w-full">
          {user &&
            (user.client.is_trial || !user.client.subscription?.subscribed) && (
              <TrialInfoBar />
            )}
          {user && !user.email_verified_at && <EmailNotVerified />}
          {children}
        </div>
        <UserGuideFunctions />
      </div>
    );
  }

  return children;
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
