import { parseISO } from 'date-fns';

export default function userFields(state = null, action) {
  // Default values
  if (state == null) {
    return {
      list: [],
      listLoading: false,
      error: null,
      current: {},
      remove: false,
      edit: false,
      new: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'USER_FIELDS_FETCHING':
      return {
        ...state,
        listLoading: true,
        error: null,
      };

    case 'USER_FIELDS_FETCHED':
      return {
        ...state,
        list: action.data
          ? action.data.map(field => {
              if (
                field.type === 'date' &&
                field.default &&
                ['now', 'null'].indexOf(field.default) === -1
              ) {
                // eslint-disable-next-line no-param-reassign
                field.default = parseISO(field.default);
              }
              return field;
            })
          : [],
        listLoading: false,
      };
    case 'USER_FIELDS_FETCH_ERROR':
      return {
        ...state,
        listLoading: false,
        error: action.message,
      };
    case 'USER_FIELDS_CLOSE_ALL_MODAL':
      return {
        ...state,
        current: {},
        remove: false,
        edit: false,
        activate: false,
        deactivate: false,
        new: false,
      };

    case 'USER_FIELDS_OPEN_NEW':
      return {
        ...state,
        new: true,
      };

    case 'USER_FIELDS_OPEN_EDIT':
      return {
        ...state,
        edit: true,
        current: action.user,
      };

    case 'USER_FIELDS_CLOSE_EDIT':
      return {
        ...state,
        edit: false,
        current: null,
        new: false,
      };

    case 'USER_FIELDS_OPEN_REMOVE':
      return {
        ...state,
        remove: true,
        current: action.user,
      };

    case 'USER_FIELDS_CLOSE_REMOVE':
      return {
        ...state,
        remove: false,
      };

    default:
      return state;
  }
}
