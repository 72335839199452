export default function flows(state = null, action) {
  // Default values
  if (state == null) {
    return {
      total: '',
      list: [],
      filters: [],
      listLoading: false,
      listLoaded: false,
      morelistLoading: false,
      morelistLoaded: false,
      userLoading: false,
      user: {},
      similar: false,
      similarEvent: {},
      params: {},
      detailsLoading: '',
      details: [],
      scrollToBottom: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'ACTIVITY_FILTERS_FETCHING':
      return {
        ...state,
        // listLoading: true,
        // listLoaded: false,
      };

    case 'ACTIVITY_FILTERS_FETCHED':
      return {
        ...state,
        filters: action.data,
        // listLoading: false,
        // listLoaded: true,
      };

    case 'ACTIVITY_FETCHING':
      return {
        ...state,
        listLoading: true,
        listLoaded: false,
        scrollToBottom: false,
        params: action.params,
      };

    case 'SCROLL_BOTTOM_REACHED':
      return {
        ...state,
        scrollToBottom: true,
      };

    case 'ACTIVITY_FETCHED':
      return {
        ...state,
        total: action.data.total || 0,
        list: action.data.data || [],
        listLoading: false,
        listLoaded: true,
      };
    case 'MORE_ACTIVITY_FETCHING':
      return {
        ...state,
        morelistLoading: true,
        morelistLoaded: false,
        scrollToBottom: false,
      };
    case 'MORE_ACTIVITY_FETCHED':
      return {
        ...state,
        total: action.data.total,
        list: [...state.list, ...action.data.data],
        morelistLoading: false,
        morelistLoaded: true,
        scrollToBottom: false,
      };
    case 'LATEST_ACTIVITY_FETCHED':
      return {
        ...state,
        total: action.data.total,
        list: action.data.data.concat(state.list),
      };
    case 'DETAILS_FETCHING':
      return {
        ...state,
        detailsLoading: action.data,
      };

    case 'DETAILS_FETCHED':
      return {
        ...state,
        userLoading: '',
        details: [...state.details, action.data],
      };
    case 'USER_FETCHING':
      return {
        ...state,
        userLoading: true,
      };

    case 'USER_FETCHED':
      return {
        ...state,
        userLoading: false,
        user: action.data,
      };
    case 'SHOW_SIMILAR':
      return {
        ...state,
        similar: true,
        similarEvent: {
          step: action.event.step?.sequence || '',
          flow: action.event.step?.flow?.id || '',
          type: action.event.event_type || '',
        },
      };

    case 'DONT_SHOW_SIMILAR':
      return {
        ...state,
        similar: false,
        similarEvent: {},
      };
    case 'LOADED_FALSE':
      return {
        ...state,
        listLoaded: false,
      };
    default:
      return state;
  }
}
