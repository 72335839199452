/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { format, intervalToDuration } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUser } from '../../actions/activity';
import Button from '../Button';
import Loader from '../Loader';
import localized from '../../i18n';
import Popup from '../Popup';
import history from '../../utils/history';

import Session from '../../img/user-session.svg';
import Time from '../../img/time-clock.svg';
import Bar from '../../img/loading-bar.svg';
import StepPreview from '../../img/step-preview.svg';
import User from '../../img/simple-user.svg';

import './index.css';

const UserModal = ({ className }) => {
  const { id } = useParams();
  const user = useSelector(state => state.activity.user);
  const loading = useSelector(state => state.activity.userLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser(id));
  }, []);

  const formatTime = seconds => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    return `${duration.hours > 0 ? `${duration.hours}h ` : ''} 
    ${duration.minutes && `${duration.minutes}m `}
    ${duration.seconds && `${duration.seconds}s `}`;
  };

  const getEventType = type => {
    switch (type) {
      case 'first_seen':
        return localized.eventitem_event_first_seen;
      case 'start_session':
        return localized.eventitem_event_start_session;
      case 'end_session':
        return localized.eventitem_event_end_session;
      case 'open_page':
        return localized.eventitem_event_open_page;
      case 'leave_page':
        return localized.eventitem_event_leave_page;
      case 'start_flow':
        return localized.eventitem_event_start_flow;
      case 'finish_flow':
        return localized.eventitem_event_finish_flow;
      case 'skip_flow':
        return localized.eventitem_event_skip_flow;
      case 'show_step':
        return localized.eventitem_event_show_step;
      case 'end_step':
        return localized.eventitem_event_end_step;
      case 'close_step':
        return localized.eventitem_event_close_step;
      case 'end_step_with_skip':
        return localized.eventitem_event_end_step_with_skip;
      case 'end_step_expire':
        return localized.eventitem_event_end_step_expire;
      case 'next_button_click':
        return localized.eventitem_event_next_button_click;
      case 'skip_button_click':
        return localized.eventitem_event_skip_button_click;
      case 'specific_button_click':
        return localized.eventitem_event_specific_button_click;
      case 'finish_button_click':
        return localized.eventitem_event_finish_button_click;
      case 'page_button_click':
        return localized.eventitem_event_page_button_click;
      case 'close_button_click':
        return localized.eventitem_event_close_button_click;
      case 'pagination_to_next_step':
        return localized.eventitem_event_pagination_to_next_step;
      case 'pagination_to_specific_page':
        return localized.eventitem_event_pagination_to_specific_page;

      default:
        return null;
    }
  };

  return (
    <Popup
      // title={localized.flow_remove_modal_title}
      className={`z-40 no-cursor absolute w-full lg:w-2/3 rounded-sm bg-faded-white shadow-2xl ${className}`}
      onClose={() => history.goBack()}
      isOpen
    >
      {loading && (
        <div className="flex w-full h-64 items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && user && (
        <div>
          <div className="flex w-full my-8">
            <div className="w-1/2 flex">
              <User className="w-auto h-auto mr-4" />
              <div>
                <div className="text-xl font-bold">
                  {!user.user ||
                  (!user.user.first_name &&
                    !user.user.last_name &&
                    !user.user.email)
                    ? localized.eventitem_anonymous
                    : null}
                </div>
                <div className="text-xl font-bold">
                  {user?.user?.first_name} {user?.user?.last_name}
                </div>
                <div className="text-medium-text-color text-sm underline">
                  {user?.user?.email}
                </div>
                <div className="text-medium-text-color text-sm underline">
                  {user?.user?.bb_identifier}
                </div>
              </div>
            </div>
            {user.activity && (
              <div className="w-1/2">
                <div className="uppercase text-secondary font-bold">
                  {localized.user_modal_latest_activity}
                </div>
                <div className="font-bold">
                  {getEventType(user.activity[0].event_type)}
                </div>
                {user.activity[0].step && (
                  <div className="flex items-center">
                    <div>
                      {user.activity[0].step.flow?.name}
                      {localized.user_modal_slash_step}
                      {user.activity[0].step.sequence}
                    </div>
                    {user.activity[0].step.thumbnail_is_available && (
                      <div className="group relative">
                        <StepPreview className="w-6 ml-2" />
                        <div className="hidden group-hover:block bg-transparent rounded absolute bottom-0 left-0 right-0 z-10 mb-2 -ml-12">
                          <div className="bg-black w-32 h-auto p-2 -mb-2 shadow ">
                            <img
                              src={user.activity[0].step.thumbnail_url}
                              alt=""
                              className="w-32 h-auto"
                            />
                          </div>
                          <div className="w-10 overflow-hidden inline-block ml-12 bg-transparent ">
                            <div className=" h-6 w-6 bg-black -rotate-45 transform origin-top-left" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="text-medium-text-color">
                  {format(
                    new Date(user.activity[0].created_at),
                    'MMM d, yyyy ',
                  )}
                  <strong className="text-text-color">
                    {format(new Date(user.activity[0].created_at), 'HH:mm:ss')}
                  </strong>
                </div>
              </div>
            )}
          </div>
          {user?.user?.custom_field_values.length !== 0 && (
            <div className=" flex flex-col -mx-6 bg-primary-100 px-6 py-8">
              {user?.user?.custom_field_values.map(item => {
                return (
                  <div className="flex pl-16">
                    <div className="w-1/4">
                      {item.client_user_field.name || ''}
                    </div>
                    <div className="w-1/4 font-bold">{item.value || ''}</div>
                  </div>
                );
              })}
            </div>
          )}
          {user.user?.statistics && (
            <div className="flex w-full my-8">
              <div className="w-1/2 pl-8">
                <div className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center">
                    <Session className="h-4 w-auto" />
                  </div>
                  <div className="w-1/2 ml-2">
                    {localized.user_modal_sessions}
                  </div>
                  <div className="font-bold">
                    {user.user.statistics.number_of_sessions}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center">
                    <Bar className="w-6 h-auto" />
                  </div>
                  <div className="w-1/2 ml-2">{localized.user_modal_total}</div>
                  <div className="font-bold">
                    {formatTime(user.user.statistics.total_duration)}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center">
                    <Time className="h-4 w-auto" />
                  </div>
                  <div className="w-1/2 ml-2">{localized.user_modal_last}</div>
                  <div className="font-bold">
                    {formatTime(user.user.statistics.current_duration)}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex">
                  <div className="w-1/3">{localized.user_modal_device}</div>
                  <div className="font-bold">
                    {user.user.statistics.device === 1 &&
                      localized.eventitem_device_mobile}
                    {user.user.statistics.device === 2 &&
                      localized.eventitem_device_tablet}
                    {user.user.statistics.device === 3 &&
                      localized.eventitem_device_desktop}
                    {user.user.statistics.device === 4 &&
                      localized.eventitem_device_bot}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/3">{localized.user_modal_os}</div>
                  <div className="font-bold">{user.user.statistics.os}</div>
                </div>
                <div className="flex">
                  <div className="w-1/3">{localized.user_modal_browser}</div>
                  <div className="font-bold">
                    {user.user.statistics.browser}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/3">{localized.user_modal_country}</div>
                  <div className="font-bold">
                    {user.user.statistics.country}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="uppercase text-secondary ml-16 font-bold">
            {localized.user_modal_events}
          </div>
          <div className="info-timeline w-full ml-8">
            <ul className="w-full">
              {user.activity &&
                user.activity.map(item => {
                  return (
                    <li className="flex w-full items-center my-2 ">
                      <span className="timeline-circle" />
                      <div className="flex w-5/6 ml-4 h-16 py-1 px-2 items-center justify-between border border-primary-100 rounded">
                        <div className="w-1/2">
                          <div className="font-bold">{item.event_type}</div>
                          {item.step && (
                            <div className="flex items-center">
                              <div>
                                {item.step.flow?.name}
                                {localized.user_modal_slash_step}
                                {item.step.sequence}
                              </div>
                              {item.step.thumbnail_is_available && (
                                <div className="group relative">
                                  <StepPreview className="w-6 ml-2" />
                                  <div className="hidden group-hover:block bg-transparent rounded absolute bottom-0 left-0 right-0 z-10 mb-2 -ml-12">
                                    <div className="bg-black w-32 h-auto p-2 -mb-2 shadow ">
                                      <img
                                        src={item.step.thumbnail_url}
                                        alt=""
                                        className="w-32 h-auto"
                                      />
                                    </div>
                                    <div className="w-10 overflow-hidden inline-block ml-12 bg-transparent ">
                                      <div className=" h-6 w-6 bg-black -rotate-45 transform origin-top-left" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="text-medium-text-color w-1/3">
                          {format(new Date(item.created_at), 'MMM d, yyyy ')}
                          <strong className="text-text-color">
                            {format(new Date(item.created_at), 'HH:mm:ss')}
                          </strong>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="flex flex-row items-center justify-end mt-12 -mx-6 -mb-4 px-6 py-4 bg-secondary-100">
            <Button
              color="primary"
              size="md"
              type="button"
              onClick={() => history.goBack()}
            >
              <div className="font-display text-white text-sm">
                {localized.user_modal_close}
              </div>
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default UserModal;
