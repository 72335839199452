import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Loader from '../Loader';
import Button from '../Button';
import localized from '../../i18n';
import chat, { openChat } from './chat';

import ErrorIcon from '../../img/feedback-error.svg';
import FeedbackIcon from '../../img/feedback-feedback.svg';
import SuggestionIcon from '../../img/feedback-suggestion.svg';
import CheckCircle from '../../img/check-circle.svg';

const Feedback = () => {
  const supportEmail = 'support@bonboarding.com';

  const authenticated = useSelector(state => state.auth.authenticated);
  const user = useSelector(state => state.auth.user);

  const [isOpen, setIsOpen] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeError, setSelectedTypeError] = useState(null);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (authenticated) {
      chat({ ...user });
    }
  }, [authenticated]);

  const selectType = type => {
    setSelectedType(type);
    setSelectedTypeError(null);
  };

  const submitFeedback = () => {
    // first validate
    let hasError = false;
    if (!selectedType) {
      setSelectedTypeError(localized.feedback_type_error);
      hasError = true;
    }
    if (!message) {
      setMessageError(localized.feedback_message_error);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    // if no error, submit
    setLoading(true);
    axios
      .post('/feedback', {
        type: selectedType,
        message,
        url: window.location.href,
      })
      .then(() => {
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          setIsOpen(false);
          setMessage('');
          setSelectedType(null);
          setLoading(false);
        }, 5000);

        setTimeout(() => {
          setSuccess(false);
        }, 5500);
      })
      .catch(error => {
        setApiError(error.response || 'unknown');
        setLoading(false);
      });
  };

  return (
    <div
      className={classNames(
        'fixed top-0 right-0 bottom-0 z-50',
        'flex flex-col justify-center',
        'overflow-hidden filter drop-shadow pl-24',
        'pointer-events-none',
      )}
    >
      <div
        className={classNames('flex transform', 'transition-all', {
          'translate-x-96 duration-300 ease-in-out': !authenticated,
          'translate-x-[16.25rem] duration-300 ease-in-out':
            authenticated && !isOpen,
          'translate-x-64 duration-300 ease-in-out':
            authenticated && !isOpen && isHovered,
          'animate-slide-in': authenticated && isOpen,
        })}
      >
        <button
          type="button"
          className={classNames(
            'flex items-center justify-center relative',
            'pl-1.5 py-3 h-[6.5rem] w-12 pr-3 rounded-l -mr-2',
            'uppercase font-black text-white cursor-pointer',
            'group pointer-events-auto',
            { 'bg-blue-600': !isOpen, 'bg-blue-900': isOpen },
          )}
          onClick={() => setIsOpen(!isOpen)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span
            className={classNames(
              'absolute inset-0 opacity-0',
              'rounded-l',
              'bg-gradient-to-r from-blue-600 to-blue-900',
              'transition-all duration-300 ease-in-out',
              { 'group-hover:opacity-100': !isOpen },
            )}
          />
          <span className="block transform -rotate-90">
            {localized.feedback_label}
          </span>
        </button>
        <div className="w-64 pr-2 rounded-bl relative pointer-events-auto">
          {/* Background */}
          <div className="absolute -z-10 w-96 rounded-bl top-0 bottom-0 -right-32 bg-gradient-to-br from-blue-900 via-blue-900 to-[#04282D]" />

          {/* Success message */}
          <div
            className={classNames(
              'absolute z-50 w-96 rounded-bl top-0 bottom-0 -right-32',
              'bg-gradient-to-br from-blue-900 via-blue-900 to-[#04282D]',
              'transition-all duration-300 ease-in-out',
              { 'opacity-0 pointer-events-none': !success },
              { 'opacity-100 pointer-events-auto': success },
            )}
          >
            <div
              className={classNames(
                'flex flex-col items-center justify-center',
                'absolute top-0 right-32 bottom-0 left-0 p-4',
                'text-white',
              )}
            >
              <div className="w-12 h-12">
                <CheckCircle />
              </div>
              <h3 className="text-sm font-medium my-4">
                {localized.feedback_success_title}
              </h3>
              <p className="text-xs font-bold text-center my-2">
                {localized.feedback_success_message}
              </p>
              <p className="text-xs font-bold text-center my-2">
                {localized.feedback_success_message_2}
              </p>
            </div>
          </div>

          {/* Error message */}
          {apiError && (
            <div className="bg-red text-white rounded-sm p-1 mt-2 mx-2.5">
              <h3 className="text-sm font-medium">
                {localized.feedback_api_error}
              </h3>
              <p className="text-xs font-bold leading-tight mt-2 mb-1">
                {apiError === 'unknown' ? (
                  <span>
                    {localized.feedback_unknown_error}
                    <a
                      href={`mailto:support@bonboarding.com?subject=Feedback - ${selectedType}&body=${encodeURI(
                        message,
                      )}`}
                      className="underline"
                    >
                      {supportEmail}
                    </a>
                  </span>
                ) : (
                  apiError
                )}
              </p>
            </div>
          )}

          {/* Feedback form */}
          <h2 className="text-sm text-white text-left mx-2.5 mt-2">
            {localized.feedback_type}
          </h2>
          {selectedTypeError && (
            <div className="bg-red text-white rounded-sm px-1 py-0.5 mt-1 mx-2.5 text-xs font-bold">
              {selectedTypeError}
            </div>
          )}
          <div className="flex justify-between px-2 mt-1 mb-4">
            <FeedbackType
              icon={<ErrorIcon />}
              title={localized.feedback_type_bug}
              onClick={() => selectType('error')}
              selected={selectedType === 'error'}
              error={!!selectedTypeError}
            />
            <FeedbackType
              icon={<FeedbackIcon />}
              title={localized.feedback_type_feedback}
              onClick={() => selectType('feedback')}
              selected={selectedType === 'feedback'}
              error={!!selectedTypeError}
            />
            <FeedbackType
              icon={<SuggestionIcon />}
              title={localized.feedback_type_suggestion}
              onClick={() => selectType('suggestion')}
              selected={selectedType === 'suggestion'}
              error={!!selectedTypeError}
            />
          </div>
          <h2 className="text-sm text-white text-left mx-2.5 mt-2">
            {localized.feedback_message}
          </h2>
          {messageError && (
            <div className="bg-red text-white rounded-sm px-1 py-0.5 mt-1 mx-2.5 text-xs font-bold">
              {messageError}
            </div>
          )}
          <div className="w-full px-2.5 mt-1">
            <textarea
              className={classNames(
                'w-full h-40 px-2 py-2 bg-white rounded',
                'transition-all duration-300 ease-in-out',
                'text-sm font-medium text-text-color',
                'border-2 ring-blue ring-opacity-50',
                'focus:outline-none focus:ring focus:border-blue',
                {
                  'border-red': !!messageError,
                  'border-white': !messageError,
                },
              )}
              value={message}
              onChange={e => {
                setMessage(e.target.value);
                setMessageError(null);
              }}
            />
          </div>
          <div className="flex justify-end mb-2 mx-2.5">
            <SubmitButton onClick={submitFeedback} loading={loading} />
          </div>
          {authenticated && (
            <>
              <div className="w-12 h-1 border-solid border-t border-blue-600 mx-auto" />
              <div className="w-full flex items-center justify-center h-12">
                <Button outline color="white" size="sm" onClick={openChat}>
                  {localized.feedback_chat_with_us}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const FeedbackType = ({ icon, title, onClick, selected, error }) => {
  return (
    <button
      type="button"
      className={classNames(
        'flex flex-col items-center relative',
        'w-20 pt-2 pb-1 bg-white rounded shadow',
        'transition-all duration-300 ease-in-out',
        {
          'transform scale-[0.85] opacity-80 z-20': !selected,
          'hover:scale-100 hover:opacity-100': !selected,
          'scale-100 opacity-100 z-10': selected,
          'ring ring-blue ring-opacity-100': selected,
          'ring ring-red ring-opacity-100': error,
        },
      )}
      onClick={onClick}
    >
      <span className="flex items-center justify-center w-10 h-10 mb-2">
        {icon}
      </span>
      <span className="text-text-color text-xs">{title}</span>
    </button>
  );
};

const SubmitButton = ({ loading, onClick }) => {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      type="button"
      className={classNames(
        'my-2',
        'px-4 py-1.5 bg-white text-text-color text-sm font-bold uppercase rounded-full',
        'transition-all duration-300 ease-in-out',
        'transform filter',
        {
          'hover:shadow-lg hover:translate-y-[-2px]': !loading,
          'active:transition-none active:brightness-90 active:translate-y-0': !loading,
        },
      )}
    >
      <span className="flex relative justify-center">
        <span
          className={classNames('transition-all duration-300 ease-in-out', {
            'opacity-100': loading,
            'opacity-0': !loading,
          })}
        >
          <Loader className="transform scale-40 translate-x-[2px] translate-y-[2px]" />
        </span>

        <span
          className={classNames('transition-all duration-300 ease-in-out', {
            'opacity-100': !loading,
            'opacity-0': loading,
          })}
        >
          {localized.feedback_send}
        </span>
      </span>
    </button>
  );
};
