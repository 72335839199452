import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ className }) => (
  <div className={`loader ${className}`}>
    <div className="loader-1">
      <div />
    </div>
    <div className="loader-2">
      <div />
    </div>
    <div className="loader-3">
      <div />
    </div>
    <div className="loader-4">
      <div />
    </div>
    <div className="loader-5">
      <div />
    </div>
    <div className="loader-6">
      <div />
    </div>
  </div>
);

export default Loader;

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};
