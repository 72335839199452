import React from 'react';
import OnboardingFunction from './OnboardingFunction';

const UserGuideFunctions = () => {
  return (
    <div id="userguidefunctions">
      <OnboardingFunction />
    </div>
  );
};

export default UserGuideFunctions;
