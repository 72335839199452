/* eslint react/button-has-type: 0 */
import React from 'react';

import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Loader from '../Loader';

const Button = ({
  children,
  type,
  onClick,
  className,
  size,
  color,
  loading,
  disabled,
  outline,
}) => {
  return (
    <button
      type={type}
      className={classNames(`btn btn--${size} btn--${color}`, className, {
        'btn--outline': outline,
        'btn--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <span className="flex relative justify-center">
        <motion.span animate={{ opacity: loading ? 1 : 0 }}>
          <Loader className="transform scale-50" />
        </motion.span>

        <motion.span
          animate={{ opacity: loading ? 0 : 1 }}
          className="btn__content"
        >
          {children}
        </motion.span>
      </span>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'white', 'danger', 'secondary']),
  outline: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: '',
  onClick: () => {},
  loading: false,
  disabled: false,
  type: 'button',
  size: 'md',
  color: 'primary',
  outline: false,
};

export default Button;
