export default function users(state = null, action) {
  // Default values
  if (state == null) {
    return {
      loading: true,
      error: null,
      list: [],
      invites: [],
      remove: false,
      current: null,
      inviteModal: false,
      changeUserRoleModal: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'USERS_FETCHING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'USERS_FETCHED':
      return {
        ...state,
        list: action.data.users,
        invites: action.data.invites,
        loading: false,
      };

    case 'USERS_FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    case 'OPEN_USER_REMOVE':
      return {
        ...state,
        remove: true,
        current: action.user,
      };

    case 'CLOSE_USER_REMOVE':
      return {
        ...state,
        remove: false,
      };

    case 'OPEN_INVITE':
      return {
        ...state,
        inviteModal: true,
      };

    case 'CLOSE_INVITE':
      return {
        ...state,
        inviteModal: false,
      };

    case 'OPEN_CHANGE_USER_ROLE':
      return {
        ...state,
        changeUserRoleModal: true,
        current: action.user,
      };

    case 'CLOSE_CHANGE_USER_ROLE':
      return {
        ...state,
        changeUserRoleModal: false,
      };

    default:
      return state;
  }
}
