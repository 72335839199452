import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CollapsingPage from 'collapsing-page';
import Logout from '../../screens/Logout';
import { logout, logoutCookie, logoutFinished } from '../../actions/auth';

import HomeIcon from '../../img/home.svg';
import FlowsIcon from '../../img/flows.svg';
import IntegrationIcon from '../../img/integration.svg';
import UsersIcon from '../../img/users.svg';
import ActivityIcon from '../../img/activity.svg';
import SubscriptionIcon from '../../img/subscription.svg';
import SettingsIcon from '../../img/settings.svg';
import LogoutIcon from '../../img/logout.svg';

import localized from '../../i18n';

import './style.css';

const SidebarMenu = () => {
  const [backgroundHeight, setBackgroundHeight] = useState(0);
  const [backgroundTop, setBackgroundTop] = useState(0);
  const [menuAppearing, setMenuAppearing] = useState(false);
  const [logoutTriggered, triggerLogout] = useState(false);
  const [logoutUrl, setLogoutUrl] = useState('');
  const active = useSelector(state => state.layout.activeMenu);
  const dispatch = useDispatch();

  return (
    <div className="pt-4 mb-12 z-10 w-full">
      <nav
        className="sidebar-nav mt-3 block w-full"
        onMouseEnter={() => {
          setMenuAppearing(true);
          setTimeout(() => setMenuAppearing(false), 100);
        }}
      >
        <ul className="list-none relative flex flex-col m-0 p-0">
          <MenuItem
            to="/dashboard"
            title={localized.sidebar_home}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<HomeIcon />}
            active={active === 'dashboard'}
          />
          <MenuItem
            to="/flows"
            title={localized.sidebar_flows}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<FlowsIcon />}
            active={active === 'flows'}
          />
          <MenuItem
            to="/integration"
            title={localized.sidebar_integration}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<IntegrationIcon />}
            active={active === 'integration'}
          />
          <MenuItem
            to="/users"
            title={localized.sidebar_users}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<UsersIcon />}
            active={active === 'users'}
          />
          <MenuItem
            to="/activity"
            title={localized.sidebar_activity}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<ActivityIcon />}
            active={active === 'activity'}
          />
          <div className="border-b border-light-primary w-6 my-1 ml-5 relative z-10" />
          <MenuItem
            to="/subscription"
            title={localized.sidebar_subscription}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<SubscriptionIcon />}
            active={active === 'subscription'}
          />
          <MenuItem
            to="/settings"
            title={localized.sidebar_settings}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<SettingsIcon />}
            active={active === 'settings'}
          />
          <MenuItem
            onClick={() => {
              triggerLogout(true);
              setLogoutUrl(document.location.href);
              dispatch(logoutCookie());
            }}
            title={localized.sidebar_logout}
            sh={setBackgroundHeight}
            st={setBackgroundTop}
            icon={<LogoutIcon />}
          />
          <li
            className={`mx-3 sidebar-animation h-8 absolute bg-primary-400 left-0 right-0 rounded-md opacity-0 ease-out duration-500
              ${!menuAppearing ? 'transition-all' : 'transition-opacity'}`}
            style={{
              height: `${backgroundHeight || 0}px`,
              top: `${backgroundTop || 0}px`,
            }}
          />
        </ul>

        <CollapsingPage
          collapse={logoutTriggered}
          onFinish={() => {
            // if the URL changed (so the user navigated away after the logout animation)
            // don't redirect to the goodbye page
            const redirect = logoutUrl === document.location.href;
            dispatch(logoutFinished(redirect));
            dispatch(logout());
          }}
        >
          <Logout />
        </CollapsingPage>
      </nav>
    </div>
  );
};

const MenuItem = ({ to, title, sh, st, icon, soon, active, onClick }) => {
  const contents = (
    <>
      {icon}
      <div className="text-base font-display ml-2">{title}</div>
      {soon && (
        <div
          className={classNames(
            'transition-all ease-out duration-500 opacity-50 group-hover:opacity-100',
            {
              'opacity-50': !active,
            },
          )}
        >
          <SoonButton />
        </div>
      )}
    </>
  );
  return (
    <li className="relative z-10 flex flex-row h-10">
      {active && (
        <div className="bg-yellow h-10 w-1 rounded-r absolute pointer-events-none" />
      )}
      {to ? (
        <Link
          to={to}
          title={title}
          className="menu-item flex flex-row pl-5 pr-4 h-10 w-full text-lg font-medium text-white transition-all ease-out duration-500 items-center justify-start group"
          onMouseEnter={e => moveToMenu(e, sh, st)}
        >
          {contents}
        </Link>
      ) : (
        <button
          type="button"
          title={title}
          className="menu-item flex flex-row pl-5 pr-4 h-10 w-full text-lg font-medium text-white transition-all ease-out duration-500 items-center justify-start group cursor-pointer"
          onMouseEnter={e => moveToMenu(e, sh, st)}
          onClick={() => onClick()}
        >
          {contents}
        </button>
      )}
    </li>
  );
};

const moveToMenu = (e, setBackgroundHeight, setBackgroundTop) => {
  let el = e.target;
  if (!el.classList.contains('menu-item')) {
    el = findParentBySelector(el, '.menu-item');
  }

  if (!el) {
    return;
  }

  const height = el.offsetHeight;
  const top = el.parentElement.offsetTop;
  setBackgroundHeight(height);
  setBackgroundTop(top);
};

const SoonButton = () => (
  <div className="ml-3 bg-primary-200 font-display font-bold rounded uppercase px-2 text-xs text-primary-600 flex items-center justify-center">
    {localized.sidebar_soon}
  </div>
);

export default SidebarMenu;

const collectionHas = (a, b) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = a.length; i < len; i++) {
    if (a[i] === b) return true;
  }
  return false;
};
const findParentBySelector = (el, selector) => {
  const all = document.querySelectorAll(selector);
  if (!el || el.parentNode === null) return null;
  let cur = el.parentNode;
  while (cur && !collectionHas(all, cur)) {
    // keep going up until you find a match
    cur = cur.parentNode; // go up
  }
  return cur; // will return null if not found
};
