/* eslint-disable react/no-array-index-key */
import React, { createContext, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Notification from './notification';

const NotificationContext = createContext();

const Notifications = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationContext.Provider value={[notifications, setNotifications]}>
      <NotificationList notifications={notifications} />
      {children}
    </NotificationContext.Provider>
  );
};

export default Notifications;

Notifications.propTypes = {
  children: PropTypes.node,
};

Notifications.defaultProps = {
  children: null,
};

const NotificationList = ({ notifications }) => {
  return createPortal(
    <div className="fixed z-50 top-0 right-0 mt-4 mr-4 flex flex-col-reverse">
      {notifications.map((notification, index) => (
        <Notification
          key={`noti-${index}`}
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeout={notification.timeout}
          zIndex={999999 - index}
        />
      ))}
    </div>,
    document.getElementsByTagName('body')[0],
  );
};

export function useNotify() {
  const [notifications, setNotifications] = useContext(NotificationContext);

  return {
    notify: notification => {
      setNotifications([...notifications, notification]);
    },
  };
}
