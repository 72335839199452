import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Twirl as Hamburger } from 'hamburger-react';
import localized from '../../i18n';
import SidebarMenu from '../SidebarMenu';
import SidebarFooter from '../SidebarFooter';
import Logo from '../../img/logo-white.svg';

import 'react-perfect-scrollbar/dist/css/styles.css';

const Sidebar = () => {
  const active = useSelector(state => state.layout.activeMenu);
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="relative lg:min-w-[16rem] lg:h-screen">
      <div
        className={`main-nav fixed z-10 inset-0 mx-0 max-w-menu-opened w-80 pt-2 pb-2 pl-14
            lg:w-68 lg:left-0 lg:top-2 lg:bottom-2 lg:max-h-screen lg:pl-2 
            transform translate-all  lg:transform-none duration-300 ease-in-out
            ${menuOpened ? '-translate-x-12 ' : '-translate-x-full '}`}
      >
        <div className="relative z-10 w-64 h-full flex flex-col bg-primary-600 rounded shadow-strong">
          <div className="px-6 pt-5 mx-auto">
            <Logo alt={localized.app_name} />
          </div>
          <div className="relative flex flex-col flex-1 w-64 items-start justify-between overflow-hidden rounded-b">
            {/* <PerfectScrollbar
              options={{
                suppressScrollX: true,
              }}
            > */}
            <div className="w-full h-full" id="sidebar-container">
              <SidebarMenu active={active} />
              <SidebarFooter />
            </div>
            {/* </PerfectScrollbar> */}
          </div>
        </div>
        <div
          className={`block lg:hidden fixed z-10 top-0 -right-20 m-4
            translate-all duration-300 ease-in-out
          `}
        >
          <Hamburger
            toggled={menuOpened}
            toggle={setMenuOpened}
            label="Toggle navigation"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
