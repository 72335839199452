export default function dashboard(state = null, action) {
  // Default values
  if (state == null) {
    return {
      loading: true,
      error: null,
      highlights: {
        new_users: {
          value: null,
          growth: null,
        },
        flow_users: {
          value: null,
          count: null,
        },
        dismissed: {
          value: null,
          count: null,
        },
        average_steps: {
          value: null,
          count: null,
          total: null,
        },
        total_users: {
          value: null,
        },
      },
      activity: [],
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'STAT_FETCHING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'STAT_FETCHED':
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case 'STAT_FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
