export default function targeting(state = null, action) {
  // Default values
  if (state == null) {
    return {
      current: null,
      targeting: false,
      saveConfirm: false,
      targetList: [],
      newTargetList: [],
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'OPEN_TARGETING':
      return {
        ...state,
        targeting: true,
        current: action.flow,
      };

    case 'CLOSE_TARGETING':
      return {
        ...state,
        targeting: false,
      };

    case 'OPEN_REMOVE':
      return {
        ...state,
        remove: true,
        current: action.flow,
      };

    case 'CLOSE_REMOVE':
      return {
        ...state,
        remove: false,
      };
    case 'TARGETING_FETCHED':
      // eslint-disable-next-line no-case-declarations
      const rules = [...action.data];
      rules.forEach((rule, index) => {
        rules[index].key =
          rule.id || `rule-${Math.round(Math.random() * 100000)}`;
      });
      return {
        ...state,
        targetList: rules,
        newTargetList: rules,
        listLoading: false,
      };
    case 'SAVE_TARGETING':
      return {
        ...state,
        newTargetList: [...state.newTargetList, action.data],
      };
    case 'UPDATE_TARGETLIST':
      return {
        ...state,
        newTargetList: action.data,
      };
    case 'OPEN_SAVE_TARGETING_CONFIRM':
      return {
        ...state,
        saveConfirm: true,
      };

    case 'CLOSE_SAVE_TARGETING_CONFIRM':
      return {
        ...state,
        saveConfirm: false,
      };
    default:
      return state;
  }
}
