import React from 'react';
import ReactDOM from 'react-dom';

// Bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from './App';

import './css/style.css';

// Bugsnag if exists
let ErrorBoundary = null;
if (process.env.REACT_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
  });

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
} else {
  ErrorBoundary = React.Fragment;
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
