import { openExtensionModal } from '../actions/flows';

// Try to send a message to the extension
// if it's installed, it will open the flow in the extension
// if it's not installed, it will open the modal to install the extension & checks if the extension is installed
const openEditedFlow = ({ flow, dispatch, callback }) => {
  try {
    window.postMessage(
      {
        type: 'BONBOARDING_EDIT_FLOW',
        flowId: flow.id,
      },
      '*',
    );
    let receivedResponse = false;
    const listener = event => {
      if (
        event.source === window &&
        event?.data?.type === 'BONBOARDING_EDIT_FLOW_STARTED'
      ) {
        receivedResponse = true;
        const w = window.open(flow.domain.domain, '_blank');
        if (w) {
          w.focus();
        }
        window.removeEventListener('message', listener);
        if (callback) {
          callback();
        }
      }
    };

    window.addEventListener('message', listener);

    setTimeout(() => {
      if (receivedResponse) return;
      window.removeEventListener('message', listener);
      dispatch(openExtensionModal(flow));
      localStorage.setItem('flowToOpen', JSON.stringify(flow));
    }, 2000);
  } catch (e) {
    // Not installed
    dispatch(openExtensionModal(flow));
    localStorage.setItem('flowToOpen', JSON.stringify(flow));
  }
};

export default openEditedFlow;
