export default function subscription(state = null, action) {
  // Default values
  if (state == null) {
    return {
      paymentModalOpen: false,
      paymentModalOnlyPaymentMethod: false,
      cancelSubscriptionModalOpen: false,
      upgradeModalOpen: false,
      successModalOpen: false,
      invoices: [],
      invoicesLoading: false,
      invoicesError: null,
      products: [],
      unlimitedPlanId: null,
      invoicePaying: false,
      invoicePayError: null,
      details: null,
      detailsLoading: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'SUBCRIPTION_PRODUCTS_FETCHED':
      return {
        ...state,
        products: action.payload.prices,
        unlimitedPlanId: action.payload.unlimited_plan_id,
      };

    case 'SUBCRIPTION_DETAILS_FETCHING':
      return {
        ...state,
        detailsLoading: true,
      };

    case 'SUBCRIPTION_DETAILS_FETCHED':
      return {
        ...state,
        detailsLoading: false,
        details: action.payload,
      };

    case 'OPEN_PAYMENT_MODAL':
      return {
        ...state,
        paymentModalOpen: true,
        paymentModalOnlyPaymentMethod: action.onlyPaymentMethod,
      };

    case 'CLOSE_PAYMENT_MODAL':
      return {
        ...state,
        paymentModalOpen: false,
        onlyPaymentMethod: false,
      };

    case 'OPEN_CANCEL_SUBSCRIPTION_MODAL':
      return {
        ...state,
        cancelSubscriptionModalOpen: true,
      };

    case 'CLOSE_CANCEL_SUBSCRIPTION_MODAL':
      return {
        ...state,
        cancelSubscriptionModalOpen: false,
      };

    case 'OPEN_SUCCESS_MODAL':
      return {
        ...state,
        paymentModalOpen: false,
        successModalOpen: true,
      };

    case 'CLOSE_SUCCESS_MODAL':
      return {
        ...state,
        successModalOpen: false,
      };
    case 'OPEN_UPGRADE_MODAL':
      return {
        ...state,
        upgradeModalOpen: true,
      };

    case 'CLOSE_UPGRADE_MODAL':
      return {
        ...state,
        upgradeModalOpen: false,
      };
    case 'INVOICES_FETCHING':
      return {
        ...state,
        invoicesLoading: true,
      };

    case 'INVOICES_FETCHED':
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.payload,
      };

    case 'INVOICES_FETCH_ERROR':
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: action.payload,
      };

    case 'INVOICE_PAYING':
      return {
        ...state,
        invoicePaying: true,
      };

    case 'INVOICE_PAID':
      return {
        ...state,
        invoicePaying: false,
      };

    case 'INVOICE_PAY_ERROR':
      return {
        ...state,
        invoicePaying: false,
        invoicePayError: action.payload,
      };

    default:
      return state;
  }
}
