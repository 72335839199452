/* eslint-disable react/jsx-no-literals */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getCookie, setCookie } from '../../utils/cookies';
import localized from '../../i18n';

const SEO = () => {
  const authenticated = useSelector(state => state.auth.authenticated);
  const user = useSelector(state => state.auth.user);
  const userHasProfile = user && user.name && user.name !== '';

  useEffect(() => {
    if (user && user.language) {
      localized.setLanguage(user.language);
    } else {
      const language = getCookie('bb_language');
      if (language) {
        localized.setLanguage(language);
      } else {
        const userLanguage =
          window.navigator.language || window.navigator.userLanguage;

        const languageString = userLanguage.split('-')[0];
        if (languageString === 'es') {
          setCookie('bb_language', 'es', 365);
          localized.setLanguage(languageString);
        } else {
          setCookie('bb_language', 'en', 365);
          localized.setLanguage('en');
        }
      }
    }
  }, [user]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{localized.seo_title}</title>
      <meta name="description" content={localized.seo_description} />

      <link rel="icon" href="favicon.ico" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/seo/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/seo/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/seo/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/seo/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/seo/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/seo/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/seo/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/seo/apple-touch-icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/seo/favicon-196x196.png"
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href="/seo/favicon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href="/seo/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/seo/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="/seo/favicon-128.png"
        sizes="128x128"
      />

      <meta name="twitter:image" content="/seo/og-bonboarding.jpg" />
      <meta name="twitter:description" content={localized.seo_description} />
      <meta name="twitter:title" content={localized.seo_title} />
      <meta name="twitter:creator" content="@FullStackMaker" />
      <meta name="twitter:card" content="summary" />
      <meta name="og:image" content="/seo/og-bonboarding.jpg" />
      <meta name="og:description" content={localized.seo_description} />
      <meta name="og:title" content={localized.seo_title} />
      <meta name="og:type" content="website" />
      <meta name="image" content="/seo/og-bonboarding.jpg" />

      <meta name="application-name" content="Bonboarding" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      <meta name="test" content="test" />
      <meta name="authenticated" content={authenticated ? 'yes' : 'no'} />

      {authenticated && (
        <>
          <script data-bb-key="oz42gj8few" src="https://go.bonboarding.com" />
          <script type="text/javascript">
            {`
              bbCheck = setInterval(() => {
                if (typeof Bonboarding !== "undefined") {
                  Bonboarding.identify({
                    uniqueID: "${user.id}",
                    firstName: "${user.first_name}",
                    email: "${user.email}",
                    signUpDate: "${user.created_at}",
                    hasProfile: ${!!userHasProfile},
                  });
                  clearInterval(bbCheck);
                }
              }, 100);
            `}
          </script>
        </>
      )}
    </Helmet>
  );
};

export default SEO;
