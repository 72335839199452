import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../Button';
import { hideNotVerified } from '../../actions/layout';
import localized from '../../i18n';

const EmailNotVerified = () => {
  const dispatch = useDispatch();
  const hidden = useSelector(state => state.layout.notVerifiedHidden);

  useEffect(() => {
    const notVerifiedHidden = sessionStorage.getItem('notVerifiedHidden');

    if (notVerifiedHidden) {
      dispatch(hideNotVerified());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API request for submitting registration
  const [{ data, loading, error }, resendVerificationEmail] = useAxios(
    {
      url: '/email/resend',
      method: 'post',
    },
    { manual: true },
  );

  if (hidden) {
    return null;
  }

  return (
    <div className="w-full pl-6 pr-4 py-2">
      <div className="bg-yellow h-12 flex rounded shadow-strong pulse-light">
        <div className="text-md text-md flex px-4 justify-center items-center">
          {!data && !error && localized.verification_email_not_verified}
          {!!data && !error && (
            <span>
              {localized.verification_email_sent}
              <span className="ml-4 text-sm font-semibold">
                {localized.verification_email_sent_check_spam}
              </span>
            </span>
          )}
          {error && (
            <span>
              {localized.verification_email_error}
              <span className="ml-4 text-sm font-semibold">
                {localized.verification_email_error_try_again}
              </span>
            </span>
          )}
          <span className="ml-4">
            <Button
              outline
              color="primary"
              size="sm"
              loading={loading}
              onClick={resendVerificationEmail}
            >
              {localized.verification_resend_email}
            </Button>
          </span>
        </div>
        <button
          type="button"
          className="ml-auto w-12 h-12 text-lg text-primary"
          onClick={() => dispatch(hideNotVerified())}
        >
          {/* eslint-disable react/jsx-no-literals */}
          &times;
        </button>
      </div>
    </div>
  );
};

export default EmailNotVerified;
