import React from 'react';
import { useSelector } from 'react-redux';
import localized from '../../i18n';
import TrialInfo from '../../img/trial-info.svg';
import history from '../../utils/history';

const TrialInfoBar = () => {
  const user = useSelector(state => state.auth.user);
  const appsumoSubscribed =
    user.client.appsumo &&
    !user.client.appsumo.refunded &&
    !user.client.appsumo.upgraded_to_stripe;

  if (
    !user ||
    !user.client ||
    user.client?.is_trial_hidden !== false ||
    appsumoSubscribed
  ) {
    return null;
  }

  return (
    <div className="w-full md:pl-6">
      <div className="w-full md:rounded-bl bg-blue-600 flex py-2 text-white">
        <div className="pl-20 pr-12 md:px-0 flex flex-col justify-center items-center w-full">
          {user.client.trial_days_left > 0 ? (
            <div className="flex flex-row items-center">
              <TrialInfo />
              <h2 className="ml-2 text-base">
                {localized.trial_bar_period_title}
              </h2>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <h2 className="text-base text-center">
                {localized.trial_bar_title}
              </h2>
            </div>
          )}
          <div
            className={
              user.client.trial_days_left > 0
                ? 'flex flex-col md:flex-row mt-2 md:mt-0.5 mb-1 items-center'
                : 'flex flex-col md:flex-row-reverse mt-2 md:mt-0.5 mb-1 items-center'
            }
          >
            <span>
              {user.client.trial_days_left > 0 ? (
                <p className="font-normal text-sm text-center">
                  {localized.trial_bar_period_sub_1}
                  {user.client.trial_days_left > 3 ? (
                    user.client.trial_days_left +
                    localized.trial_bar_period_sub_2
                  ) : (
                    <strong className="font-bold">
                      {user.client.trial_days_left}
                      {localized.trial_bar_period_sub_2_left}
                    </strong>
                  )}
                  {localized.trial_bar_period_sub_3}
                </p>
              ) : (
                <p className="font-semibold text-sm text-center">
                  {localized.trial_bar_sub}
                </p>
              )}
            </span>
            <button
              type="button"
              className={`mt-2 md:mt-0 mr-3 md:mx-3 border border-white px-3 py-0.5 rounded-full text-sm font-semibold whitespace-nowrap
                transition-all duration-300 ease-in-out bg-white bg-opacity-0
                hover:bg-opacity-10 active:bg-opacity-100 active:text-blue-900 active:transition-none`}
              onClick={() => history.push('/subscription')}
            >
              {user.client.trial_days_left > 3
                ? localized.trial_bar_btn_anytime
                : localized.trial_bar_btn_now}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialInfoBar;
