/* eslint-disable camelcase */
import axios from 'axios';

// fetch flows
export function fetchFlows() {
  return dispatch => {
    dispatch({ type: 'FLOWS_FETCHING' });
    axios('/flows')
      .then(response => {
        dispatch({ type: 'FLOWS_FETCHED', data: response.data });
      })
      .catch(error => {
        dispatch({ type: 'FLOWS_FETCH_ERROR', message: error.message });
      });
  };
}

// open edit flow modal
export function openEditModal(flow) {
  return { type: 'OPEN_EDIT', flow };
}

// open new flow modal
export function openNewModal() {
  return { type: 'OPEN_NEW' };
}

// close new/edit flow modal
export function closeEditModal() {
  return { type: 'CLOSE_EDIT' };
}

// close all modal
export function closeAllModal() {
  return { type: 'CLOSE_ALL_MODAL' };
}

// open live flow modal
export function openLiveModal(flow) {
  return { type: 'OPEN_LIVE', flow };
}

// close live flow modal
export function closeLiveModal() {
  return { type: 'CLOSE_LIVE' };
}

// open duplicate flow modal
export function openDuplicateModal(flow) {
  return { type: 'OPEN_DUPLICATE', flow };
}

// close duplicate flow modal
export function closeDuplicateModal() {
  return { type: 'CLOSE_DUPLICATE' };
}

// open activate modal
export function openActivateModal(flow) {
  return { type: 'OPEN_ACTIVATE', flow };
}

// close activate modal
export function closeActivateModal() {
  return { type: 'CLOSE_ACTIVATE' };
}

// close deactivate modal
export function openDeactivateModal(flow) {
  return { type: 'OPEN_DEACTIVATE', flow };
}

// close deactivate modal
export function closeDeactivateModal() {
  return { type: 'CLOSE_DEACTIVATE' };
}

// open remove modal
export function openRemoveModal(flow) {
  return { type: 'OPEN_REMOVE', flow };
}

// close remove modal
export function closeRemoveModal() {
  return { type: 'CLOSE_REMOVE' };
}

// open can't activate modal
export function openCantActivateModal(flow) {
  return { type: 'OPEN_CANT_ACTIVATE', flow };
}

// close can't activate modal
export function closeCantActivateModal() {
  return { type: 'CLOSE_CANT_ACTIVATE' };
}

// open free user flow modal
export function openFreeUserModal() {
  return { type: 'OPEN_FREE_USER' };
}

// close free user flow modal
export function closeFreeUserModal() {
  return { type: 'CLOSE_FREE_USER' };
}

// open free user flow modal
export function openFreeUserTargetingModal() {
  return { type: 'OPEN_FREE_USER_TARGETING' };
}

// close free user flow modal
export function closeFreeUserTargetingModal() {
  return { type: 'CLOSE_FREE_USER_TARGETING' };
}

// open extension modal
export function openExtensionModal(flow) {
  return { type: 'OPEN_EXTENSION_MODAL', flow };
}

// close extension modal
export function closeExtensionModal() {
  return { type: 'CLOSE_EXTENSION_MODAL' };
}

// open extension installed modal
export function openExtensionInstalledModal(flow) {
  return { type: 'OPEN_EXTENSION_INSTALLED_MODAL', flow };
}

// close extension installed modal
export function closeExtensionInstalledModal() {
  return { type: 'CLOSE_EXTENSION_INSTALLED_MODAL' };
}

// open flow settings modal
export function openFlowSettingsModal(flow) {
  return { type: 'OPEN_FLOW_SETTINGS', flow };
}

// close flow settings modal
export function closeFlowSettingsModal() {
  return { type: 'CLOSE_FLOW_SETTINGS' };
}
