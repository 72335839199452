export default function integrations(state = null, action) {
  // Default values
  if (state == null) {
    return {
      loading: true,
      error: null,
      enabled: [],
      disabled: [],
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'INTEGRATIONS_FETCHING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'INTEGRATIONS_FETCHED':
      return {
        ...state,
        enabled: action.data.enabled,
        disabled: action.data.disabled,
        loading: false,
      };

    case 'INTEGRATIONS_FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
}
