import LocalizedStrings from 'react-localization';
import en from './languages/en';
import es from './languages/es';

const strings = new LocalizedStrings({
  en,
  es,
});

export default strings;
