/* eslint-disable react/jsx-no-literals */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button';
import { openChat } from '../Feedback/chat';
import history from '../../utils/history';
import localized from '../../i18n';

// img
import Feedback from '../../img/feedback.svg';

const SidebarFooter = () => {
  const sidebarFooterEl = useRef(null);
  const [checked, setChecked] = useState(false);
  const [canPullUpSidebarFooter, setCanPullUpSidebarFooter] = useState(false);
  const [isSidebarFooterPulledUp, setIsSidebarFooterPulledUp] = useState(false);

  useEffect(() => {
    let count = 0;
    const heightChecker = setInterval(() => {
      if (count > 200) {
        clearInterval(heightChecker);
        return;
      }

      const sidebarEl = document.getElementById('sidebar-container');
      const sidebarHeight = sidebarEl.clientHeight;
      const sidebarTop = sidebarEl.getBoundingClientRect().top;
      const sidebarFooterHeight = sidebarFooterEl.current.clientHeight;
      const sidebarFooterTop = sidebarFooterEl.current.getBoundingClientRect()
        .top;
      // eslint-disable-next-line no-plusplus
      count++;

      if (sidebarHeight > 0 && sidebarFooterHeight > 0) {
        clearInterval(heightChecker);
        setChecked(true);
        setCanPullUpSidebarFooter(
          sidebarHeight + sidebarTop < sidebarFooterHeight + sidebarFooterTop,
        );
      }
    }, 100);
  }, []);

  const pullUpSidebar = () => {
    if (isSidebarFooterPulledUp || !canPullUpSidebarFooter) return;

    setIsSidebarFooterPulledUp(true);
    const sidebarEl = document.getElementById('sidebar-container');
    const sidebarHeight = sidebarEl.clientHeight;
    const sidebarTop = sidebarEl.getBoundingClientRect().top;
    const sidebarFooterHeight = sidebarFooterEl.current.clientHeight;
    const sidebarFooterTop = sidebarFooterEl.current.getBoundingClientRect()
      .top;
    const topAdjustment = sidebarHeight + sidebarTop - sidebarFooterTop;
    const newTop = sidebarFooterHeight - topAdjustment;
    sidebarFooterEl.current.style.transform = `translateY(-${newTop}px)`;
  };

  const pullDownSidebar = () => {
    if (!isSidebarFooterPulledUp) return;

    setIsSidebarFooterPulledUp(false);
    sidebarFooterEl.current.style.transform = 'translateY(0)';
  };

  const user = useSelector(state => state.auth.user);
  const appsumoSubscribed =
    user?.client?.appsumo &&
    !user.client.appsumo?.refunded &&
    !user.client.appsumo?.upgraded_to_stripe;

  return (
    <>
      <div
        ref={sidebarFooterEl}
        className={`absolute ${!canPullUpSidebarFooter ? 'bottom-0' : ''} ${
          checked ? 'opacity-100' : 'opacity-0'
        } z-10 mt-auto rounded-b flex flex-col w-64 bg-yellow justify-end transition-all ease-in-out duration-300`}
        onMouseEnter={pullUpSidebar}
        onMouseLeave={pullDownSidebar}
      >
        <div
          className="absolute w-full h-full bg-yellow left-0 transform -skew-y-5"
          style={{ top: '-20px' }}
        />
        {!user.client.is_trial &&
        user.client.subscription &&
        !user.client.subscription.subscribed &&
        !appsumoSubscribed ? (
          <div className="rounded-b bg-yellow relative flex flex-col items-center pb-2">
            <div className="font-display font-semibold mt-6 mb-2">
              {localized.sidebar_free_title}
            </div>
            <div className="w-full px-4">
              <div className="rounded-full bg-last-100 w-full h-4 my-2">
                <div
                  className="bg-primary rounded-full h-full"
                  style={{
                    width: `${Math.min(
                      user.client.subscription?.usage_in_period,
                      100,
                    )}%`,
                  }}
                />
              </div>
            </div>
            <div className="font-display font-semibold text-sm text-muted mb-4 text-center">
              ({Number(user.client.subscription?.usage_in_period).toFixed(2)}% -{' '}
              {user.client.subscription.used_maus}
              {localized.sidebar_free_maus}
            </div>
            <div className="font-display font-semibold text-sm mb-2">
              {localized.sidebar_free_sub1}
              <strong className="uppercase font-bold">
                {localized.sidebar_free_sub2}
              </strong>
              {localized.sidebar_free_sub3}
            </div>
            <Button
              color="primary"
              size="md"
              type="button"
              onClick={() =>
                history.push(
                  '/subscription?upgrade-modal&utm_source=webapp&utm_medium=sidebar&utm_campaign=upgrade_free',
                )
              }
            >
              <p className="font-display text-white font-bold">
                {localized.sidebar_free_upgrade}
              </p>
            </Button>

            <div className="w-8 h-1 mt-4 border-b border-primary" />
          </div>
        ) : null}

        <div className="rounded-b w-full bg-yellow relative flex flex-col items-center self-end pt-2">
          <div className="inline-flex">
            <Feedback />
            <div className="font-display font-bold text-sm ml-2 pt-3">
              {localized.sidebar_questions}
            </div>
          </div>

          <Button
            color="primary"
            outline
            size="sm"
            type="button"
            onClick={openChat}
            className="my-2"
          >
            {localized.sidebar_contact}
          </Button>

          <div className="w-8 h-1 mt-2 mb-1 border-t border-primary" />

          <a
            href={process.env.REACT_APP_DOCS_URL}
            target="_blank"
            className="btn btn--sm btn--primary btn--outline mt-2 mb-5"
            rel="noreferrer"
          >
            {localized.sidebar_docs}
          </a>
        </div>
      </div>
      {canPullUpSidebarFooter ? (
        <div
          className={`absolute z-10 bottom-0 left-0 w-full pointer-events-none bg-gradient-to-t from-[rgba(0,0,0,0.2)] transition-all ease-in-out duration-300 ${
            isSidebarFooterPulledUp ? 'opacity-0 h-1' : 'h-4'
          }`}
        />
      ) : null}
    </>
  );
};

export default SidebarFooter;
