import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { motion, AnimatePresence } from 'framer-motion';

const Notification = ({ title, message, color, timeout, zIndex }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, bounds] = useMeasure({ scroll: true, polyfill: ResizeObserver });

  useEffect(() => {
    setIsVisible(true);

    if (timeout) {
      setTimeout(() => {
        setIsVisible(false);
      }, timeout);
    }
  }, []);

  const styles = {
    success: {
      background: 'bg-blue',
      text: 'text-white',
      border: 'border-blue',
    },
    yellow: {
      background: 'bg-yellow',
      text: 'text-text-color',
      border: 'border-yellow',
    },
    danger: {
      background: 'bg-red',
      text: 'text-white',
      border: 'border-red',
    },
    info: {
      background: 'bg-white',
      text: 'text-text-color',
      border: 'border-muted-200',
    },
  };

  /* stylelint-disable */
  return (
    <div className="relative" style={{ zIndex }}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="w-96"
            initial={{ height: 0 }}
            animate={{ height: bounds.height + 25 }}
            exit={{ height: 0 }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9, transformOrigin: 'top right' }}
            className={`absolute top-0 z-10
            rounded-md px-4 py-2 mb-2 w-96 shadow-xl border-hairline border
            origin-bottom-right ${styles[color].background} ${styles[color].text} ${styles[color].border}`}
            ref={ref}
          >
            {title && <h4>{title}</h4>}
            {message && <p className="text-sm">{message}</p>}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
  /* stylelint-enable */
};

export default Notification;

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
  timeout: PropTypes.number,
  zIndex: PropTypes.number,
};

Notification.defaultProps = {
  title: '',
  message: '',
  color: '',
  timeout: 4000,
  zIndex: 0,
};
