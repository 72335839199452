export default function settings(state = null, action) {
  // Default values
  if (state == null) {
    return {
      changePasswordIsOpen: false,
      updateProfileIsOpen: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'PASSWORD_CHANGE_MODAL_TOGGLE':
      return {
        ...state,
        changePasswordIsOpen: !state.changePasswordIsOpen,
      };

    case 'UPDATE_PROFILE_MODAL_TOGGLE':
      return {
        ...state,
        updateProfileIsOpen: !state.updateProfileIsOpen,
      };

    default:
      return state;
  }
}
