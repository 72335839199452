export default function flows(state = null, action) {
  // Default values
  if (state == null) {
    return {
      list: [],
      listLoading: false,
      error: null,
      current: null,
      remove: false,
      edit: false,
      duplicate: false,
      live: false,
      activate: false,
      cantActivate: false,
      deactivate: false,
      new: false,
      freeuser: false,
      freeusertargeting: false,
      extensionModalOpen: false,
      flowToEdit: null,
      extensionInstalledModalOpen: false,
      flowSettings: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'FLOWS_FETCHING':
      return {
        ...state,
        error: null,
        listLoading: true,
      };

    case 'FLOWS_FETCHED':
      return {
        ...state,
        list: action.data,
        listLoading: false,
      };
    case 'FLOWS_FETCH_ERROR':
      return {
        ...state,
        listLoading: false,
        error: action.message,
      };
    case 'CLOSE_ALL_MODAL':
      return {
        ...state,
        current: {},
        remove: false,
        edit: false,
        activate: false,
        deactivate: false,
        new: false,
      };

    case 'OPEN_NEW':
      return {
        ...state,
        new: true,
      };

    case 'OPEN_EDIT':
      return {
        ...state,
        edit: true,
        current: action.flow,
      };

    case 'CLOSE_EDIT':
      return {
        ...state,
        edit: false,
        current: null,
        new: false,
      };

    case 'OPEN_ACTIVATE':
      return {
        ...state,
        activate: true,
        current: action.flow,
      };

    case 'CLOSE_ACTIVATE':
      return {
        ...state,
        activate: false,
      };

    case 'OPEN_CANT_ACTIVATE':
      return {
        ...state,
        cantActivate: true,
        current: action.flow,
      };

    case 'CLOSE_CANT_ACTIVATE':
      return {
        ...state,
        cantActivate: false,
      };

    case 'OPEN_LIVE':
      return {
        ...state,
        live: true,
        current: action.flow,
      };

    case 'CLOSE_LIVE':
      return {
        ...state,
        live: false,
      };

    case 'OPEN_DUPLICATE':
      return {
        ...state,
        duplicate: true,
        current: action.flow,
      };

    case 'CLOSE_DUPLICATE':
      return {
        ...state,
        duplicate: false,
      };

    case 'OPEN_REMOVE':
      return {
        ...state,
        remove: true,
        current: action.flow,
      };

    case 'CLOSE_REMOVE':
      return {
        ...state,
        remove: false,
      };

    case 'OPEN_DEACTIVATE':
      return {
        ...state,
        deactivate: true,
        current: action.flow,
      };

    case 'CLOSE_DEACTIVATE':
      return {
        ...state,
        deactivate: false,
      };

    case 'OPEN_FREE_USER':
      return {
        ...state,
        freeuser: true,
      };

    case 'CLOSE_FREE_USER':
      return {
        ...state,
        freeuser: false,
      };

    case 'OPEN_FREE_USER_TARGETING':
      return {
        ...state,
        freeusertargeting: true,
      };

    case 'CLOSE_FREE_USER_TARGETING':
      return {
        ...state,
        freeusertargeting: false,
      };
    case 'OPEN_EXTENSION_MODAL':
      return {
        ...state,
        extensionModalOpen: true,
        flowToEdit: action.flow,
      };
    case 'CLOSE_EXTENSION_MODAL':
      return {
        ...state,
        extensionModalOpen: false,
      };
    case 'OPEN_EXTENSION_INSTALLED_MODAL':
      return {
        ...state,
        extensionModalOpen: false,
        extensionInstalledModalOpen: true,
        flowToEdit: action.flow,
      };
    case 'CLOSE_EXTENSION_INSTALLED_MODAL':
      return {
        ...state,
        extensionModalOpen: false,
        extensionInstalledModalOpen: false,
      };
    case 'OPEN_FLOW_SETTINGS':
      return {
        ...state,
        flowSettings: true,
        current: action.flow,
      };
    case 'CLOSE_FLOW_SETTINGS':
      return {
        ...state,
        flowSettings: false,
      };
    default:
      return state;
  }
}
