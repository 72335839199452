export default function bookmarks(state = null, action) {
  // Default values
  if (state == null) {
    return {
      list: [],
      listLoading: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'BOOKMARKS_FETCHING':
      return {
        ...state,
        listLoading: true,
      };

    case 'BOOKMARKS_FETCHED':
      return {
        ...state,
        list: action.data,
        listLoading: false,
      };

    default:
      return state;
  }
}
