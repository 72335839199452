/* eslint import/prefer-default-export: 0 */

// Hide/show admin dashboard layout
export function showAdminDashboard(visible) {
  return { type: 'ADMIN_DASHBOARD', visible };
}

// Set active menu
export function setActiveMenu(active) {
  return { type: 'SET_ACTIVE_MENU', active };
}

// Hide not verified message
export function hideNotVerified() {
  sessionStorage.setItem('notVerifiedHidden', true);
  return { type: 'HIDE_NOT_VERIFIED' };
}
