const es = {
  app_name: 'Bonboarding',

  seo_title: 'Bonboarding | Impulsa la Adopción de Usuarios',
  seo_description:
    'Crea experiencias de onboarding sorprendentes y recorridos interactivos. No pierda nunca más usuarios por confusión.',

  // Generic terms
  continue: 'Continuar',
  copy_value: 'Copiar valor',
  copied: '¡Copiado!',

  // Feedback
  feedback_label: 'Feedback',
  feedback_type: '¿Qué te gustaría reportar?',
  feedback_type_bug: 'Error',
  feedback_type_feedback: 'Comentarios',
  feedback_type_suggestion: 'Sugerencias',
  feedback_type_error: 'Por favor elige un tipo',
  feedback_message: 'Tu feedback',
  feedback_message_error: 'Por favor ingresa un mensaje',
  feedback_send: 'Enviar',
  feedback_api_error: '¡Vaya! Algo salió mal',
  feedback_unknown_error:
    'Por favor, inténtalo de nuevo o escribe un correo a ',
  feedback_success_title: 'Gracias por tu feedback',
  feedback_success_message: 'Apreciamos mucho tu tiempo para escribirnos.',
  feedback_success_message_2:
    'Tu feedback es realmente valioso y nos ayuda a mejorar a Bonboarding.',
  feedback_chat_with_us: 'o chatea con nosotros',

  // Login
  login_form_email: 'Correo electrónico',
  login_form_password: 'Contraseña',
  login_form_submit: 'Enviar',
  login_error: '¡Oh no! Probablemente escribiste mal tu correo o contraseña',
  login_error_forgotten_password: 'Quizás intenta restablecer tu contraseña',
  login_link_to_forgotten_password: 'Restablece tu contraseña',
  login_no_account: '¿No tienes cuenta? ¡No hay problema!',
  login_link_to_register: '¡Regístrate ahora!',
  login_logging_in: 'Iniciando sesión',
  login_magic_title:
    'Hemos enviado un correo con un enlace para iniciar sesión',
  login_magic_subtitle:
    'Revisa tu bandeja de entrada. El enlace es válido por 30 minutos.',
  login_magic_back: 'volver al inicio de sesión',
  login_magic_err_title: 'El enlace está roto o ha expirado',
  login_magic_err_subtitle: 'No pudimos iniciar tu sesión',
  login_magic_err_back: 'vuelve e inténtalo de nuevo',

  // Logout
  goodbye: '¡Adiós!',
  logout_login_again: 'Volver al inicio de sesión',

  // Forgotten password form
  forgotten_password_title: 'Restablecer Tu Contraseña',
  forgotten_password_form_email: 'Correo electrónico',
  forgotten_password_form_submit: 'Restablecer Contraseña',
  forgotten_password_description: `Solo introduce tu E-mail,
    y te enviaremos un mensaje con un enlace para restablecer tu contraseña.`,
  forgotten_password_form_password: 'Nueva Contraseña',
  forgotten_password_success: 'Restablecimiento de Contraseña enviado',
  forgotten_password_success_steps:
    'Revisa tu bandeja de entrada para un correo con los siguientes pasos',
  forgotten_password_back_to_login: 'Volver al inicio de sesión',
  forgotten_password_error: `**No pudimos enviar un correo electrónico para restablecer la contraseña.**  
Tal vez escribiste mal tu E-mail o intentaste reenviarlo varias veces en poco tiempo.`,
  forgotten_password_link_not_valid: `**El enlace para restablecer la contraseña no es válido.**  
Intenta de nuevo o escribe un correo a [support@bonboarding.com](mailto:support@bonboarding.com)`,
  forgotten_password_try_again: 'Intenta de nuevo',
  forgotten_password_reset_success: 'Has restablecido tu contraseña con éxito',
  forgotten_password_reset_success_steps:
    'Ahora puedes iniciar sesión con tu nueva contraseña',
  forgotten_password_reset_go_to_login: 'Ir al inicio de sesión',
  forgotten_password_unknown_error: `**No pudimos restablecer tu contraseña.**  
Intenta de nuevo o escribe un correo a [support@bonboarding.com](mailto:support@bonboarding.com)`,

  // Verification
  verification_email_not_verified: 'Tu correo electrónico no está verificado.',
  verification_email_sent: `Te hemos enviado un correo electrónico de verificación.`,
  verification_email_sent_check_spam: `¡No olvides revisarl tu carpeta de spam!`,
  verification_email_error: `¡Ups! Algo no salió como se esperaba.`,
  verification_email_error_try_again: 'Por favor, intenta de nuevo.',
  verification_resend_email: 'Reenviar',

  email_verified_title: `¡Todo listo! Hemos verificado tu correo electrónico.`,
  email_verified_description: `Puedes continuar configurando tus procesos de onboarding`,
  email_verification_error_verified_title:
    'El correo electrónico ya está verificado',
  email_verification_error_title: `¡Ey! Algo no está del todo bien`,
  email_verification_error_description: `No pudimos verificar tu correo electrónico. Tal vez el enlace ha expirado, por favor intenta de nuevo con un nuevo código de verificación`,

  invite_verification_loading: 'We are verifying your invitation...',
  invite_verified_title: `You've been invited.`,
  invite_verified_description:
    'You can continue setting up your Bonboarding account',
  invite_verification_error_verified_title:
    'The invitation has already been accepted',
  invite_verification_error_title: `Ey! Something's not quite right`,
  invite_verification_error_description: `We couldn't accept your invitation. Maybe the link has expired, or you already created an account with your email address. Please try again with a new verification code`,

  // AppSumo
  appsumo_verification_loading: 'We are verifying your app sumo purchase...',
  appsumo_verified_title: `You've been verified.`,
  appsumo_verified_description:
    'You can continue setting up your Bonboarding account',
  appsumo_verification_error_title: `Ey! Something's not quite right`,
  appsumo_verification_error_description: `We couldn't accept your app sumo token. Please contact us at info@bonboarding.com`,

  // Subscription
  trial_bar_period_title: 'Bienvenido a tu período de prueba',
  trial_bar_period_sub_1: 'Tienes ',
  trial_bar_period_sub_2: ' días ',
  trial_bar_period_sub_2_left: ' días restantes ',
  trial_bar_period_sub_3: `para probar las funciones pagadas de Bonboarding.`,
  trial_bar_title: `Estás usando las funciones gratuitas de Bonboarding`,
  trial_bar_sub:
    'para flujos y pasos ilimitados, segmentar a tus usuarios y eliminar la marca de agua.',
  trial_bar_btn_anytime: 'Mejora en cualquier momento',
  trial_bar_btn_now: 'Mejora ahora',

  // Register form
  register_title: 'Prueba gratuita de 14 días',
  register_subtitle: 'Acceso completo a todas las características',
  register_feature_title_1: 'Registro rápido y gratuito',
  register_feature_description_1: `No se necesita tarjeta de crédito.
    ¡No puede ser más fácil!`,
  register_feature_title_2: 'Empieza de inmediato',
  register_feature_description_2: `¡Es muy sencillo comenzar!
    Crea fácilmente flujos de onboarding impresionantes con el editor visual.`,
  register_feature_title_3: 'Olvídate de los usuarios confundidos',
  register_feature_description_3: `Ayúdales con recorridos y asegúrate
    de que siempre sepan cómo usar las características de tu producto.`,
  register_form_title: 'Comienza con tu cuenta',
  register_form_subtitle:
    '¡Crea tu primer recorrido de producto en solo un minuto!',
  register_form_email: 'Tu correo electrónico de negocios',
  register_form_full_name: 'Tu nombre',
  register_form_url: 'La URL de tu aplicación web',
  register_form_url_subtitle:
    'Usa la URL donde quieras añadir los recorridos de producto',
  register_form_password: 'Contraseña',
  register_form_terms_text_1: `Acepto los `,
  register_form_terms_link: 'Términos de Uso',
  register_form_terms_text_2: ' y la ',
  register_form_privacy_link: 'Política de Privacidad',
  register_form_terms_text_3: ' de Bonboarding.',
  register_form_submit: 'Crea tu cuenta',
  register_form_submit_comment: 'No se necesita tarjeta de crédito',
  register_footer_copy: '© Bonboarding · ',
  register_footer_privacy: 'Privacidad',
  register_footer_terms: 'términos',
  register_back_to_login: '¿Ya tienes una cuenta? Inicia sesión aquí.',

  password_hide: 'Ocultar',
  password_show: 'Mostrar',

  // Validations
  validation_required: 'Este campo es obligatorio',
  validation_invalid_email: 'El correo electrónico es inválido',
  validation_email_taken:
    'Este correo electrónico ya está en uso. ¡Intenta iniciar sesión!',
  validation_incorrect_password: 'La contraseña es incorrecta',
  validation_password_mismatch: `La confirmación de la contraseña no coincide con la contraseña`,
  validation_accept_terms:
    'Debes aceptar los términos y la política de privacidad',
  validation_url_min_length:
    'La URL debe tener al menos 8 caracteres de longitud',

  password_validator_description:
    'Nos importa la seguridad, por favor elige una contraseña que tenga lo siguiente:',
  password_validator_lowercase: 'Un carácter en minúscula',
  password_validator_uppercase: 'Un carácter en mayúscula',
  password_validator_special_character: 'Un número o carácter especial',
  password_validator_min_length: 'Mínimo de 8 caracteres',

  // Error pages
  error_404_page: `# ¡Ups!

## Parece una situación **404** de las de toda la vida.`,
  error_404_call_to_action: `Por favor, no rompas Internet, vuelve a la seguridad:`,
  error_404_link_to_homepage: 'Página de inicio',
  error_404_link_to_dashboard: 'Dashboard',
  error_404_link_to_contact: 'Contáctanos',

  // RecentActivity
  recent_user: 'Usuario',
  recent_signup: 'Registro',
  recent_status: 'Estado',
  recent_progress: 'Progreso',
  recent_step: 'Paso',
  recent_of: 'de',

  // SideBar
  sidebar_home: 'Inicio',
  sidebar_flows: 'Flujos',
  sidebar_integration: 'Integración',
  sidebar_users: 'Usuarios',
  sidebar_activity: 'Actividad',
  sidebar_subscription: 'Suscripción',
  sidebar_settings: 'Ajustes',
  sidebar_logout: 'Cerrar sesión',
  sidebar_soon: 'Próximamente',
  sidebar_questions: '¿Preguntas? ¿Comentarios?',
  sidebar_contact: 'Ponte en contacto',
  sidebar_docs: 'Consulta nuestras guías',

  sidebar_free_title: 'Uso Mensual',
  sidebar_free_maus: ' Usuarios Activos Mensuales (UAMs)',
  sidebar_free_sub1: 'Estás en el plan ',
  sidebar_free_sub2: 'gratuito',
  sidebar_free_sub3: '',
  sidebar_free_upgrade: 'Mejorar ahora',

  // Dashboard
  dashboard_hello: 'Hola ',
  dashboard_subtitle: 'Aquí está tu actividad reciente de onboarding',
  dashboard_new_user: 'Usuarios Nuevos',
  dashboard_onboarded: 'Incorporados',
  dashboard_dismissed: 'Descartados',
  dashboard_average_steps: 'Pasos Promedio',
  dashboard_average_steps_comment: '{count} de {total} total',
  dashboard_total_users: 'Usuarios Totales',
  dashboard_activity: 'Actividad',
  dashboard_period: 'Período...',
  dashboard_flow: 'Flujo...',
  dashboard_flow_all: 'Todos los flujos',
  period_week: '7 días',
  period_month: 'Mes',
  period_year: 'Año',
  dashboard_chart_flows: 'Usuarios nuevos incorporados',
  dashboard_empty_chart: 'Esperando datos',
  dashboard_empty_chart_subtitle:
    'una vez este flujo tenga alguna actividad, se mostrará aquí',
  dashboard_stat_error: 'Error',
  dashboard_fetch_title: 'No pudimos obtener tus datos',
  dashboard_fetch_subtitle: 'Por favor, intenta de nuevo más tarde',
  dashboard_fetch_stats: 'Recargar',

  // Flows
  flows_title: 'Flujos',
  flows_subtitle: 'Porque no sólo los nuevos usuarios necesitan guía',
  flows_sub1:
    'Este es el lugar donde puedes configurar los flujos de onboarding de tu aplicación.',
  flows_sub2:
    'Pero, ¿por qué detenerte en solamente onboarding? ¡Tienes una nueva característica increíble! ¡Muéstrasela a todos tus usuarios existentes!',
  flows_sub3:
    'Los nuevos flujos comienzan como inactivos, así que tus usuarios finales no los verán hasta que los hayas pulido a la perfección.',
  flows_add: 'Agregar nuevo',
  flows_error_title: 'No pudimos obtener tus flujos',
  flows_error_subtitle: 'Por favor, intenta de nuevo más tarde',
  flows_error_reload: 'Recargar',

  // FlowItem
  flowitem_steps: 'pasos',
  flowitem_users: 'usuarios',
  flowitem_avgtime: 'tiempo promedio',
  flowitem_avgfinished: 'completado',
  flowitem_demo: 'Demostración',
  flowitem_draft: 'Borrador',
  flowitem_inactive: 'Inactivo',
  flowitem_active: 'Activo',
  flowitem_menu: 'Opciones',
  flowitemmenu_editor: 'Editor',
  flowitemmenu_editor_no_domain:
    'Primero debes configurar la dirección de tu dominio',
  flowitemmenu_edit: 'Editar',
  flowitemmenu_activate: 'Activar',
  flowitemmenu_deactivate: 'Desactivar',
  flowitemmenu_duplicate: 'Duplicar',
  flowitemmenu_targeting: 'Segmentación',
  flowitemmenu_settings: 'Ajustes',
  flowitemmenu_remove: 'Eliminar',

  // ExtensionModal
  extension_modal_title: 'Instalar la Extensión',
  extension_modal_subtitle1_1: 'Es muy fácil empezar',
  extension_modal_subtitle1_2: 'a editar tu flujo!',
  extension_modal_subtitle2: 'Solo instala la ',
  extension_modal_subtitle3_firefox: 'Extensión de Bonboarding para Firefox,',
  extension_modal_subtitle3_chrome: 'Extensión de Bonboarding para Chrome,',
  extension_modal_subtitle4: '¡y listo!',
  extension_button_firefox: 'IR A ADD-ONS DE FIREFOX',
  extension_button_chrome: 'IR A LA TIENDA WEB DE CHROME',
  extension_modal_no_title: 'Ehm... Eso no va a funcionar',
  extension_modal_no_subtitle:
    'El editor de Bonboarding está disponible en la versión de escritorio de Google Chrome o Firefox.',
  extension_modal_no_subtitle2:
    'Intenta de nuevo desde uno de esos navegadores.',

  // ExtensionInstalledModal
  extension_installed_modal_title: '¡Todo listo!',
  extension_installed_modal_sub1:
    'Para tener siempre a mano el Estudio de Bonboarding:',
  extension_installed_modal_first_step:
    'Haz clic en el icono del rompecabezas cerca de la barra de URL',
  extension_installed_modal_second_step:
    'Fija nuestra extensión para un acceso rápido',
  extension_installed_modal_ready:
    '¿Estás listo para crear unos recorridos de producto asombrosos?',
  extension_installed_modal_button: 'Editar tu flujo',

  // FlowModals
  flow_remove_modal_title: 'Eliminar flujo',
  flow_remove_modal_1:
    '¿Estás seguro de que quieres eliminar el siguiente flujo?',
  flow_remove_modal_2: 'Esta acción no se puede deshacer.',
  flow_remove_modal_3:
    'Toda la configuración, referencias y estadísticas históricas de usuarios también se eliminarán.',
  flow_remove_modal_4: '¿Por qué no simplemente lo desactivas?',
  flow_remove_modal_cancel: 'Cancelar',
  flow_remove_modal_ok: 'Entiendo, eliminarlo',
  flow_new_modal_title: 'Nuevo flujo',
  flow_edit_modal_title: 'Editando flujo',
  flow_new_modal_1: 'Nombre del flujo',
  flow_new_modal_save: 'Guardar',
  flow_new_modal_empty_name: 'El campo del nombre no puede estar vacío',
  flow_live_modal_title: '¡Este flujo está activo y en funcionamiento!',
  flow_live_modal_1:
    '¿Estás seguro de que quieres hacer cambios en él? Se reflejará de inmediato a tus usuarios.',
  flow_live_modal_2:
    'Recomendamos que crees una copia y edites la nueva en su lugar.',
  flow_live_modal_duplicate: 'Editar una versión duplicada en borrador',
  flow_live_modal_close: 'Cerrar',
  flow_live_modal_edit: 'Entiendo, editar este flujo de todos modos',
  flow_duplicate_modal_title: 'Duplicar flujo',
  flow_duplicate_modal_1: `¿Quieres mantener el estado `,
  flow_duplicate_modal_2: `activo `,
  flow_duplicate_modal_3: `o prefieres crear una versión en `,
  flow_duplicate_modal_4: `borrador `,
  flow_duplicate_modal_5: `en su lugar?`,
  flow_duplicate_modal_draft: '¿Deseas duplicar este flujo?',
  flow_duplicate_modal_cancel: 'Cancelar',
  flow_duplicate_modal_yes: 'Sí, duplicarlo',
  flow_duplicate_modal_yes_active1: 'Mantener el estado ',
  flow_duplicate_modal_yes_active2: 'activo',
  flow_duplicate_modal_yes_active3: '',
  flow_duplicate_modal_yes_draft1: 'Duplicar una versión en ',
  flow_duplicate_modal_yes_draft2: 'borrador',
  flow_duplicate_modal_yes_draft3: '',
  flow_activate_modal_title: 'Activar flujo',
  flow_activate_modal_1: '¿Estás listo para activar el siguiente flujo?',
  flow_activate_modal_2: 'Estará disponible inmediatamente para tus usuarios.',
  flow_activate_modal_cancel: 'Cancelar',
  flow_activate_modal_ok: 'Activarlo',
  flow_cant_activate_modal_title: `El flujo no se puede activar`,
  flow_cant_activate_modal_1: `Está apuntando a un dominio de desarrollo. Por favor, cámbialo en el editor.`,
  flow_cant_activate_modal_button: 'Abrir en editor',
  flow_deactivate_modal_title: 'Desactivar flujo',
  flow_deactivate_modal_1: '¿Estás listo para desactivar el siguiente flujo?',
  flow_deactivate_modal_2:
    'Se desactivará de inmediato y no aparecerá para tus usuarios',
  flow_deactivate_modal_cancel: 'Cancelar',
  flow_deactivate_modal_ok: 'Desactivarlo',
  flow_freeuser_modal_title: 'Flujos ilimitados',
  flow_freeuser_modal_sub1:
    'Has alcanzado el límite de un flujo activo para el plan gratuito.',
  flow_freeuser_modal_sub2: 'Actualiza ahora a nuestro plan ',
  flow_freeuser_modal_sub3: 'crecimiento',
  flow_freeuser_modal_sub4: ' para crear nuevos flujos sin limitaciones.',
  flow_freeuser_modal_upgrade: 'Actualizar ahora',
  flow_freeuser_modal_price: 'a partir de solo $99/mes',
  flow_freeuser_targeting_modal_title: 'Segmentar Usuarios',
  flow_freeuser_targeting_modal_sub1:
    'Segmentar tus flujos a segmentos de usuarios específicos está disponible en nuestro plan ',
  flow_freeuser_targeting_modal_sub2: 'crecimiento',
  flow_freeuser_targeting_modal_sub3: '.',

  // Configuración de flujo
  flow_settings_modal_title: 'Configuración de flujo',
  flow_settings_modal_replay_title: 'Repetir después de finalizar',
  flow_settings_modal_replay_sub:
    'Si está habilitado, el flujo se reproducirá de nuevo en la próxima sesión del usuario',
  flow_settings_modal_restart_beginning_title: 'Reiniciar desde el principio',
  flow_settings_modal_restart_beginning_sub:
    'Si está habilitado, el flujo siempre comenzará con el primer paso en lugar de lo que vendría a continuación',
  flow_settings_modal_default_domain_title:
    'Dominio predeterminado para edición',
  flow_settings_modal_default_domain_sub:
    'Esto no afecta las reglas de segmentación, solo dónde se muestra el flujo cuando comienzas a editarlo',
  flow_settings_modal_save: 'Guardar',
  flow_settings_modal_cancel: 'Cancelar',

  // Segmentación de flujo
  flow_targeting_modal_title: 'Segmentar usuarios',
  flow_targeting_modal_subtitle1:
    'Puedes definir qué usuarios deben ver este flujo.',
  flow_targeting_modal_subtitle2:
    'Si no seleccionas ninguna regla de segmentación, el flujo se mostrará a todos los usuarios.',
  flow_targeting_modal_users1: 'Si ',
  flow_targeting_modal_users2: 'definiste campos personalizados',
  flow_targeting_modal_users3:
    ' para tus usuarios, esos valores también pueden usarse para la segmentación',
  flow_targeting_modal_new_users_title: 'Usuarios nuevos',
  flow_targeting_modal_new_users_text:
    'Usuarios nuevos que no hayan iniciado sesión en el sistema anteriormente. (usar junto con la fecha de registro, si quieres excluir a usuarios que ya existían cuando el flujo se activó)',
  flow_targeting_modal_logged_in_users_title: 'Usuarios registrados',
  flow_targeting_modal_logged_in_users_text:
    'Usuarios que han iniciado sesión en tu sistema.',
  flow_targeting_modal_session_count_title: 'Conteo de sesiones',
  flow_targeting_modal_session_count_text:
    'La cantidad de veces que el usuario visitó tu sitio',
  flow_targeting_modal_last_seen_title: 'Última visita',
  flow_targeting_modal_last_seen_text:
    'La fecha de la última vez que el usuario visitó tu sitio.',
  flow_targeting_modal_first_seen_title: 'Primera visita',
  flow_targeting_modal_first_seen_text:
    'La fecha de la primera vez que el usuario visitó tu sitio.',
  flow_targeting_modal_total_duration_title: 'Duración total',
  flow_targeting_modal_total_duration_text:
    'El tiempo total que el usuario pasó en tu sitio. (en minutos)',
  flow_targeting_modal_current_session_duration_title:
    'Duración de la sesión actual',
  flow_targeting_modal_current_session_duration_text:
    'El tiempo que el usuario ha pasado en tu sitio en la sesión actual. (en minutos)',
  flow_targeting_modal_country_title: 'País',
  flow_targeting_modal_country_text: `El país, basado en la dirección IP de la última sesión del usuario.`,
  flow_targeting_modal_device_title: 'Dispositivo',
  flow_targeting_modal_device_text: `El dispositivo de la sesión actual del usuario.`,
  flow_targeting_modal_domain_title: 'Dominio',
  flow_targeting_modal_domain_text: `El dominio de la página visitada.`,
  flow_targeting_modal_bookmark_title: 'Página en marcadores',
  flow_targeting_modal_bookmark_text:
    'Una URL en marcadores de una página en tu sitio.',
  flow_targeting_modal_bookmark_disabled_reason: `No puedes usar esta regla de segmentación porque no hay una URL en marcadores. Puedes marcar páginas en "marcadores" dentro del editor.`,
  flow_targeting_modal_first_name_title: 'Nombre',
  flow_targeting_modal_first_name_text: `El primer nombre del usuario.`,
  flow_targeting_modal_last_name_title: 'Apellido',
  flow_targeting_modal_last_name_text: `El apellido del usuario.`,
  flow_targeting_modal_email_title: 'Correo electrónico',
  flow_targeting_modal_email_text: `El correo electrónico del usuario.`,
  flow_targeting_modal_signup_date_title: 'Fecha de registro',
  flow_targeting_modal_signup_date_text: 'La fecha de registro del usuario.',
  flow_targeting_modal_custom_text: `Campo personalizado del usuario de {type}. Predeterminado: {default}.`,
  flow_targeting_modal_flow_title: 'Flujo finalizado',
  flow_targeting_modal_flow_text:
    'Dirigir a usuarios que ya terminaron con otro flujo.',
  flow_targeting_modal_flow_disabled_reason: `No puedes usar esta regla de segmentación porque no hay otro flujo que se pueda usar como criterio.`,

  flow_targeting_and: 'y',
  flow_targeting_or: 'o',
  flow_targeting_add_filter: 'Agregar filtro',
  flow_targeting_add_new_filter: 'Agregar nuevo filtro',
  flow_targeting_add_or_filter: 'Agregar filtro "o"',
  flow_targeting_add_new_or_filter: 'Agregar nuevo filtro "o"',

  condition_select: 'Seleccionar...',
  condition_equals: 'Igual',
  condition_not_equals: 'No igual',
  condition_less_than: 'Menor que',
  condition_less_than_or_equal: 'Menor o igual a',
  condition_greater_than: 'Mayor que',
  condition_greater_than_or_equal: 'Mayor o igual a',
  condition_true: 'Verdadero',
  condition_false: 'Falso',
  condition_is: 'Es',
  condition_is_not: 'No es',
  condition_same_day: 'Mismo día',
  condition_not_same_day: 'No el mismo día',
  condition_before: 'Antes',
  condition_before_or_same_day: 'Antes o el mismo día',
  condition_after: 'Después',
  condition_after_or_same_day: 'Después o el mismo día',
  condition_new_user: 'Usuario nuevo',
  condition_not_new_user: 'No usuario nuevo',
  condition_logged_in_user: 'Usuario registrado',
  condition_not_logged_in_user: 'No usuario registrado',
  condition_contains: 'Contiene',
  condition_not_contains: 'No contiene',

  flow_targeting_confirm_modal_title: 'Guardar nuevas reglas',
  flow_targeting_confirm_modal_subtitle:
    'Este flujo está actualmente activo. Cualquier cambio realizado tendrá efecto inmediatamente.',
  flow_targeting_confirm_modal_question:
    '¿Estás seguro de que quieres cambiar las reglas de segmentación?',
  flow_targeting_confirm_modal_cancel: 'Cancelar',
  flow_targeting_confirm_modal_update: 'Sí, actualizar las reglas',

  flow_added: '¡MUY BIEN! Acabas de agregar un nuevo flujo',
  flow_added_message: 'Todavía no está activo. Ve y configura los pasos.',
  flow_updated: '¡Listo! Actualizaste el flujo',
  flow_add_error: 'Hubo un error al crear tu flujo',
  flow_update_error: 'Hubo un error al actualizar tu flujo',
  flow_duplicated: '¡Hecho! Duplicaste el flujo',
  flow_duplicated_error: 'Hubo un error al duplicar tu flujo',
  flow_activated: '¡Felicidades! Acabas de activar el flujo',
  flow_activated_message: 'Está visible para la audiencia seleccionada',
  flow_activated_error: 'Hubo un error al activar tu flujo',
  flow_deactivated: 'Desactivaste el flujo',
  flow_deactivated_message: 'No está visible para nadie',
  flow_deactivated_error: 'Hubo un error al desactivar tu flujo',
  flow_removed: 'Acabas de eliminar el flujo',
  flow_removed_error: 'Hubo un error al eliminar tu flujo',
  flow_targeting_updated: '¡Hecho! Agregaste las reglas',
  flow_targeting_update_error: 'Hubo un error al agregar tus reglas',

  integration_title: 'Integración',
  integration_subtitle: 'No podría ser más fácil',
  integration_sub1: 'Empezar es realmente sencillo.',
  integration_sub2:
    'Primero, necesitas decirnos el nombre de dominio de tu aplicación web:',
  integration_sub3:
    'Y copiar esta línea de código en todos tus archivos HTML, **justo antes del cierre** de la etiqueta `</head>`.',
  integration_sub4:
    '¡Eso es todo! Ahora puedes empezar a crear tus flujos de onboarding.',
  integration_additional_info_title: 'Pasando Información Adicional',
  integration_additional_info: `Debes pasar la clave API cada vez que inicializas Bonboarding.
    Pero también puedes incluir otros datos sobre tu usuario registrado. Estos valores luego pueden 
    ser utilizados para dirigir tus flujos o agregar como marcadores de posición en tus pasos.`,
  integration_additional_info_link: `Hay cuatro campos de usuario predeterminados:
  - \`uniqueID\` (la ID del usuario en tu plataforma)
  - \`firstName\`
  - \`lastName\`
  - \`email\`
  
Pero puedes agregar tus propios campos personalizados en la página de Usuarios:`,
  integration_edit_user_fields_button: 'Visitar la Página de Usuarios',
  integration_additional_info_change_title:
    '¿Qué sucede si un usuario inicia sesión o sus datos cambian?',
  integration_additional_info_change:
    'Simple. Solo cambia el objeto de configuración. No necesitas activar nada más, Bonboarding detectará tus cambios automáticamente.',
  integration_additional_info_change_1:
    'Inicializar sin datos específicos del usuario:',
  integration_additional_info_change_2:
    'Una vez que el usuario está determinado actualiza la configuración:',
  integration_additional_info_change_3:
    'Actualiza la configuración si los datos del usuario cambian:',
  integration_domain: 'El nombre de dominio de tu aplicación web',
  integration_domain_placeholder: 'https://',
  integration_domain_save: 'Guardar cambios',
  integration_additional_domains_title: 'Dominios adicionales',
  integration_additional_domains_description: `Puedes definir múltiples dominios (o subdominios), para que puedas ejecutar tus flujos de Bonboarding en ellos o probarlos en tu servidor local antes de lanzarlos en tu sitio en vivo.

Estos dominios **deben** incluir el protocolo (\`http://\` o \`https://\`) y los números de puerto también, si aplican.

No necesitas agregar explícitamente todos los dominios y subdominios, pero asegúrate de *marcar los dominios de desarrollo* para que no contemos la actividad del usuario en esos.`,
  integration_wildcard_domain_title: 'Comodín en dominios',
  integration_wildcard_domain: `Puedes usar el símbolo de estrella (\`*\`) como un comodín para subdominios, pero debe usarse de acuerdo con las siguientes reglas para funcionar correctamente:
- El protocolo de la URL **debe** ser \`http:\` o \`https:\`. \`com.example.app://*.example.com\` no funcionará.
- El comodín **debe** estar ubicado en un subdominio dentro del componente de nombre de host. \`https://*.com\` no funcionará.
- La URL **no debe** contener más de un comodín. \`https://*.*.example.com\` no funcionará.`,
  integration_dev_domain_placeholder: 'http://localhost:3000',
  integration_domain_dev: 'DEV',
  integration_domain_dev_description:
    'No rastreamos a tus usuarios en tus dominios de desarrollo. Pueden ser utilizados para probar tus flujos en tu servidor local.',
  integration_domain_no_delete_active:
    'No puedes eliminar este dominio, porque se usa en algunos de tus flujos activos:',
  integration_additional_domain_add: 'Agregar nuevo dominio',
  integration_domain_updated: 'Actualizamos tus dominios',
  integration_domain_update_error: 'Hubo un error al actualizar tus dominios',
  integration_domain_belongs_to_active_flow_1: '¡Ten cuidado!',
  integration_domain_belongs_to_active_flow_2:
    'Este dominio se usa en algunos de tus flujos activos:',
  integration_domain_bonboarding:
    'No puedes agregar bonboarding.com como dominio',

  integration_wordpress_title: 'Integración con WordPress',
  integration_wordpress_description:
    'Integrar Bonboarding con tu sitio de WordPress es sencillo con nuestro plugin de WordPress.',
  integration_wordpress_description_2: 'Aquí te mostramos cómo puedes hacerlo:',
  integration_step_1_title: 'Descargar el Plugin:',
  integration_step_1_button: 'Plugin de WordPress de Bonboarding',
  integration_step_1_button_title:
    'Descarga el plugin de WordPress de Bonboarding como un archivo zip.',
  integration_step_1_description: `
  - Haz clic en el botón **Descargar**.`,
  integration_step_2_title: 'Instalar el Plugin:',
  integration_step_2_description: `
  
  - Inicia sesión en tu panel de control de WordPress.
  - Navega a **Plugins > Añadir Nuevo > Subir Plugin**.
  - Haz clic en **Elegir Archivo** y selecciona el archivo zip del plugin de Bonboarding que descargaste.
  - Haz clic en **Instalar Ahora**. Una vez instalado el plugin, haz clic en **Activar**.
  `,
  integration_step_3_title: 'Configurar el Plugin:',
  integration_step_3_description: `
  
  - En tu panel de control de WordPress, navega a **Ajustes > Bonboarding** en el menú de la izquierda.
  - Introduce tu **clave API de Bonboarding**.
  - Elige dónde reproducir tus recorridos de producto.
  - Haz clic en **Guardar Cambios**.
  `,
  integration_step_4_title: 'Crear Recorridos de Producto:',
  integration_step_4_description:
    'Con el plugin activado y configurado, ahora puedes comenzar a crear flujos de incorporación y recorridos de producto desde tu panel de Bonboarding.',

  // Users
  users_title: 'Usuarios',
  users_subtitle: '¡Guíalos! ¡Enséñales! ¡Ayúdales!',
  users_sub1:
    'Tus usuarios son el corazón de los recorridos y flujos de tu producto.',
  users_sub2:
    'Descubre todo sobre ellos, define qué datos recoger y observa cómo se adaptan a tu producto.',
  users_coll_title: 'Datos recogidos',
  users_coll_subtitle:
    'No hay dos usuarios iguales. Dirígete a ellos basándote en criterios personalizados',
  users_coll_sub1:
    'Puedes definir qué datos recopilamos sobre tus usuarios. Esto se puede utilizar para dirigirte a ellos en tus recorridos. Puede ser cualquier cosa, desde su nombre y correo electrónico hasta la fecha de su última compra o su idioma preferido.',
  users_available_field: 'Campos disponibles',
  user_fields_error_title: 'No pudimos obtener tus campos disponibles',
  user_fields_error_subtitle: 'Por favor, inténtalo de nuevo más tarde',
  user_fields_error_reload: 'Recargar',
  users_new: 'Añadir nuevo',
  user_field_default: 'por defecto:',
  user_field_locked: 'Este campo predeterminado no se puede modificar',
  user_field_empty: 'vacío',
  user_field_empty_string: 'cadena vacía',
  users_quick_integration: 'Instrucciones rápidas de integración',
  users_integration_sub1:
    'Agrega el siguiente código a tu sitio para informarnos sobre los valores que pertenecen al usuario:',
  users_integration_sub2: `(reemplaza los valores en **negrita** con los atributos del usuario actual)

Cada nombre de campo se convierte en **camelCase**, por lo que la primera letra de cada palabra se capitaliza,
excepto la primera palabra.

Puedes ver todos tus campos en el ejemplo anterior.`,
  users_integration_help: '¿Necesitas más ayuda?',
  users_integration_button: 'Visita la página de Integraciones',

  users_usage: 'Uso',
  users_upgrade: 'Mejora tu plan',
  users_maus: ' Usuarios Activos Mensuales',
  users_usage_sub1: 'eso es ',
  users_usage_sub2: ' uso en tu período de facturación actual',
  users_usage_bracket1: '(tu suscripción incluye ',
  users_usage_bracket2: ' UAM, se reinicia el ',
  users_usage_bracket3: ')',
  users_usage_trial: 'Tienes UAM ilimitados en tu prueba',
  users_usage_unlimited: 'Tienes UAM ilimitados',

  // Modal de nuevo usuario y editar
  user_fields_new_modal_title: 'Añadir nuevo campo',
  user_fields_edit_modal_title: 'Editar campo',
  user_fields_new_modal_1: 'Nombre del campo',
  user_fields_modal_type: 'Tipo',
  user_fields_modal_default: 'Valor predeterminado',
  user_fields_modal_empty_name: 'El campo de nombre no puede estar vacío',
  user_fields_modal_empty: 'Vacío',
  user_fields_modal_true: 'Verdadero',
  user_fields_modal_false: 'Falso',
  user_fields_modal_text_title: 'Texto: ',
  user_fields_modal_text:
    'usa este tipo de campo para cualquier valor de texto, como país, nombre, idioma, nombre de usuario, etc.',
  user_fields_modal_text2:
    'Bonboarding usará este valor si no especificas uno para un usuario; o para usuarios que ya existen en nuestra base de datos.',
  user_fields_modal_number_title: 'Número: ',
  user_fields_modal_number:
    'usa este tipo de campo para cualquier valor numérico, como edad, precios, etc',
  user_fields_modal_number2:
    'Bonboarding usará este valor si no especificas uno para un usuario; o para usuarios que ya existen en nuestra base de datos.',
  users_calendar: 'Calendario',
  user_fields_modal_date_title: 'Fecha: ',
  user_fields_modal_date:
    'Fecha: usa este tipo de campo para cualquier valor de fecha, como fecha de nacimiento, último pago, fecha de inscripción, etc.',
  user_fields_modal_date2:
    'Bonboarding usará este valor si no especificas uno para un usuario; o para usuarios que ya existen en nuestra base de datos.',
  user_fields_modal_date_current: 'Fecha actual',
  user_fields_modal_switch_title: 'Switch: ',
  user_fields_modal_switch:
    'Switch: usa este tipo de campo para cualquier valor verdadero/falso, como comprobar si el usuario es suscriptor, si aceptó los términos, etc.',
  user_fields_modal_switch2:
    'Bonboarding usará este valor si no especificas uno para un usuario; o para usuarios que ya existen en nuestra base de datos.',
  user_fields_modal_create: 'Crear',
  user_fields_modal_save: 'Guardar',

  user_field_added: 'Listo! Acabas de añadir un nuevo campo de usuario',
  user_field_updated: '¡Listo! Has actualizado el campo de usuario',
  user_field_add_error: 'Hubo un error al crear tu campo de usuario',
  user_field_update_error: 'Hubo un error al actualizar tu campo de usuario',

  // Modal de eliminar usuario
  user_field_remove_modal_title: 'Eliminar campo de usuario',
  user_field_remove_modal_1:
    '¿Estás seguro de que quieres eliminar este campo?',
  user_field_remove_modal_2:
    'Su valor se eliminará de todos los usuarios, y de cualquier criterio de targeting en el que se haya utilizado.',
  user_field_remove_modal_cancel: 'Cancelar',
  user_field_remove_modal_ok: 'Entiendo, eliminarlo',
  user_field_removed: 'Acabas de eliminar el campo de usuario',
  user_field_removed_error: 'Hubo un error al eliminar tu campo de usuario',

  // User export
  users_export_title: 'Exportar Usuarios',
  users_export_description:
    'Exporta todos tus datos de usuario a un archivo CSV.',
  users_export_comment: 'Puede tardar 1-2 minutos en descargar el archivo.',
  users_export_button: 'Descargar CSV',
  users_export_time_range_placeholder: 'Rango de tiempo',
  users_export_time_range_current: 'Periodo de facturación actual',
  users_export_time_range_30days: 'Últimos 30 días',
  users_export_time_range_halfyear: 'Últimos año y medio',
  users_export_time_range_year: 'Último año',
  users_export_time_range_alltime: 'Todo el tiempo',
  users_export_success: 'Empezó la descarga de CSV',
  users_export_error: 'Error al descargar CSV',

  // Activity
  activity_title: 'Actividad',
  activity_subtitle: 'Toda la actividad de tus usuarios en un solo lugar',
  activity_filter_filter: 'filtro',
  activity_flow_all: 'Todos los recorridos',
  activity_flow: 'Recorrido...',
  activity_to: 'a',
  activity_filter_now: 'ahora',
  activity_filter_now_live: 'En vivo',
  activity_calendar: 'Calendario',
  activity_filter_reset: 'Reiniciar',
  activity_filter_search: 'Buscar',
  activity_filter_advanced: 'búsqueda avanzada',
  activity_filter_add_filter: 'Añadir filtro',
  activity_filter_dropdown: 'Filtrar...',
  activity_advanced_type: 'Tipo',
  activity_events_total: 'Total',
  activity_events_events: ' eventos',
  activity_events_waiting: 'Esperando el primer evento',
  activity_events_none: 'No hay eventos para este criterio',
  activity_event_header_event: 'Evento',
  activity_event_header_user: 'Usuario',
  activity_event_header_step: 'Paso, recorrido',
  activity_event_header_date: 'Ocurrió el',

  // EventItem
  eventitem_menu: 'Opciones',
  eventitem_details: 'Detalles',
  eventitem_view_user: 'Ver Usuario',
  eventitem_similar: 'Mostrar similares',
  eventitem_step: 'Paso',
  eventitem_viewed_step: 'visto el paso',
  eventitem_average_time: 'tiempo promedio para este paso',
  eventitem_total_in_flow: 'tiempo total en el recorrido',
  eventitem_device: 'dispositivo:',
  eventitem_device_mobile: 'móvil',
  eventitem_device_tablet: 'tablet',
  eventitem_device_desktop: 'ordenador',
  eventitem_device_bot: 'bot',
  eventitem_os: 'SO:',
  eventitem_browser: 'navegador:',
  eventitem_country: 'país:',
  eventitem_higlight: '(resaltar)',
  eventitem_popup: '(popup)',
  eventitem_anonymous: 'Anónimo',

  // event item - event types
  eventitem_event_first_seen: 'Primera Vez Visto',
  eventitem_event_start_session: 'Iniciar Sesión',
  eventitem_event_end_session: 'Finalizar Sesión',
  eventitem_event_open_page: 'Abrir Página',
  eventitem_event_leave_page: 'Dejar Página',
  eventitem_event_start_flow: 'Iniciar Recorrido',
  eventitem_event_finish_flow: 'Finalizar Recorrido',
  eventitem_event_skip_flow: 'Saltar Recorrido',
  eventitem_event_show_step: 'Mostrar Paso',
  eventitem_event_end_step: 'Finalizar Paso',
  eventitem_event_close_step: 'Cerrar Paso',
  eventitem_event_end_step_with_skip: 'Finalizar Paso Con Salto',
  eventitem_event_end_step_expire: 'Finalizar Paso (Expira)',
  eventitem_event_button_click: 'Botón cliqueado',
  eventitem_event_next_button_click: 'al próximo paso',
  eventitem_event_skip_button_click: 'para saltar el flow',
  eventitem_event_specific_button_click: 'a paso específico',
  eventitem_event_close_button_click: 'para cerrar flow',
  eventitem_event_finish_button_click: 'para terminar flow',
  eventitem_event_page_button_click: 'para ir a página',
  eventitem_event_pagination_to_next_step: 'Paginación al Siguiente Paso',
  eventitem_event_pagination_to_specific_page: 'Paginación a Página Específica',

  // User Modal
  user_modal_latest_activity: 'Última actividad',
  user_modal_sessions: 'Sesiones:',
  user_modal_total: 'Duración total:',
  user_modal_last: 'Última sesión:',
  user_modal_device: 'Dispositivo:',
  user_modal_os: 'SO:',
  user_modal_browser: 'Navegador:',
  user_modal_country: 'País:',
  user_modal_events: 'Eventos',
  user_modal_close: 'Cerrar',
  user_modal_slash_step: ' / paso ',

  // Subscription
  subscription_title: 'Suscripción',
  subscription_subtitle: 'Gestiona tu suscripción y facturas',
  subscription_card_title: 'Actualmente estás en nuestra',
  subscription_card_daytrial: 'prueba de 14 días',
  subscription_card_daytrial_special_name: 'Descuento para Early-Bird',
  subscription_card_daytrial_special: 'Prueba de dos meses',
  subscription_card_daytrial_special_explanation:
    'en lugar del periodo normal de prueba de 14 días',
  subscription_card_period: 'periodo',
  subscription_card_free: 'gratis',
  subscription_growth_membership: 'crecimiento',
  subscription_lifetime_membership: 'lifetime',
  subscription_card_membership: 'membresía',
  subscription_card_unlimited: 'Plan Ilimitado',
  subscription_card_trial_title: '¡Esperamos que te guste!',
  subscription_card_trial_ends: 'Tu periodo de prueba termina el ',
  subscription_card_unlimited_ends: 'Tu plan ilimitado termina el ',
  subscription_card_trial_subtitle:
    'Después de eso, continuarás con nuestra membresía gratuita.',
  subscription_card_upgrade: 'Actualiza ahora',

  subscription_card_appsumo_mau: ' monthly active users',
  subscription_card_appsumo_1: 'Unlimited Product Tours & Steps',
  subscription_card_appsumo_2: 'User Segmentation',
  subscription_card_appsumo_3: 'Full Control Over the look',
  subscription_card_appsumo_4: 'User Level Tracking',
  subscription_card_appsumo_5: 'Actionable Insights',
  subscription_card_appsumo_6: 'Removal of branding',
  subscription_card_appsumo_7: 'Integrations',
  subscription_card_appsumo_8: 'Role Based Access',

  subscription_why: '¿Por qué vale la pena actualizar?',
  subscription_why_1: 'flujos ilimitados',
  subscription_why_2: 'pasos ilimitados',
  subscription_why_3: 'dirigir flujos a usuarios específicos',
  subscription_why_4: 'sin marca de agua',
  subscription_why_5: 'análisis detallados',
  subscription_why_6: '¡y mucho más!',
  subscription_card_thanks: '¡Gracias!',
  subscription_card_billed: 'Se te está facturando ',
  subscription_card_curr_period: '(periodo actual:',
  subscription_card_mau: 'Tu límite de usuarios activos mensuales es ',
  subscription_card_next_invoice: 'Próxima factura: ',
  subscription_card_next_invoice_charged: '(cobrado el ',
  subscription_card_card_ending: ' - tarjeta terminada en ',
  subscription_card_update_payment: 'Actualizar método de pago',
  subscription_card_canceled: 'Tu suscripción ha sido cancelada.',
  subscription_card_canceled_2: 'Está activa hasta el {date}',
  subscription_cancel_membership: 'Cancelar membresía',
  subscription_previous_invoices: 'Facturas anteriores',
  subscription_invoice_monthly: '(mensual)',
  subscription_invoice_yearly: '(anual)',
  subscription_unlimited: ' Ilimitados ',
  subscription_mau: ' UAM ',
  subscription_paid: ' PAGADO',
  subscription_paid_on: '(pagado el ',
  subscription_due: ' PENDIENTE',
  subscription_pay_now: 'Pagar ahora',
  subscription_no_invoices: 'No tienes ninguna factura.',
  subscription_invoice_payment_error: 'Hubo un error al pagar tu factura',

  subscription_success_modal_title: 'Pago exitoso',
  subscription_update_success_modal_title: 'Método de pago actualizado',
  subscription_success_modal_sub1: '¡Gracias por suscribirte a Bonboarding!',
  subscription_success_modal_sub2:
    '¡Ahora puedes continuar utilizando todas nuestras funciones!',
  subscription_success_modal_sub3: '¿Entonces, a qué estás esperando?',
  subscription_success_modal_sub4:
    '¡Vamos, crea algunos recorridos de producto asombrosos!',
  subscription_success_modal_close: 'Cerrar',

  payment_modal_title: 'Suscríbete',
  payment_modal_cancel: 'Cancelar',
  payment_modal_update_payment_title: 'Actualizar Método de Pago',
  payment_modal_update_payment: 'Actualizar Pago',
  payment_modal_subscribe: 'Suscribirse',

  payment_method_error: 'Hubo un error con tu método de pago',

  cancel_subscription_modal_title: 'Cancelar suscripción',
  cancel_subscription_modal_sub1:
    'Nos daría pena verte partir... pero así es la vida.',
  cancel_subscription_modal_sub2:
    'Si tienes un momento, por favor déjanos una nota explicando por qué decidiste cancelar tu suscripción, para que podamos mejorar nuestro servicio.',
  cancel_subscription_modal_sub3:
    'Si cancelas ahora, podrás seguir utilizando todas las funciones de tu suscripción hasta el ',
  cancel_subscription_modal_sub4:
    'Para cancelar tu suscripción, por favor introduce tu contraseña aquí:',
  cancel_subscription_modal_password: 'Contraseña',
  cancel_subscription_modal_close_btn: 'Cerrar ventana',
  cancel_subscription_modal_cancel_btn: 'Cancelar mi suscripción',
  cancel_subscription_modal_success: 'Suscripción cancelada',
  cancel_subscription_modal_error: 'La contraseña era incorrecta',

  upgrade_modal_title: 'Mejorar suscripción',
  upgrade_modal_sub:
    'Cuéntanos tus necesidades y nos pondremos en contacto contigo en menos de 24 horas',
  upgrade_modal_info_title: '¿Estás cerca del límite de UAMs?',
  upgrade_modal_info_sub1:
    'No te preocupes, no los bloquearemos, puedes seguir usando la',
  upgrade_modal_info_sub2: 'herramienta sin limitaciones ni interrupciones',
  upgrade_modal_cancel: 'Cancelar',
  upgrade_modal_send: 'Enviar solicitud de mejora',
  upgrade_modal_success: 'Hemos recibido tu solicitud de mejora',

  // Modal de suscripción
  dropdown_maus: ' UAMs',
  dropdown_monthly: 'mensual',
  dropdown_yearly: 'anual',
  subscription_modal_package: 'Paquete',
  subscription_modal_mau_explain:
    'cuántos usuarios únicos están utilizando tu aplicación cada mes',
  subscription_modal_billing_period: 'Periodo de facturación',
  subscription_modal_per_month: '/mes)',
  subscription_modal_due_today: 'Total a pagar hoy',
  subscription_modal_billing_details: 'Detalles de facturación',
  subscription_modal_company_name: 'Nombre de la empresa',
  subscription_modal_line1: 'Dirección - Línea 1',
  subscription_modal_line2: 'Dirección - Línea 2',
  subscription_modal_city: 'Ciudad',
  subscription_modal_zip: 'Código postal',
  subscription_modal_country: 'País',
  subscription_modal_vat: 'Número de impuesto',
  subscription_modal_card: 'Tarjeta',
  subscription_modal_cancel: 'Cancelar',
  subscription_modal_subscribe: 'Suscribirse',

  // Settings
  settings_title: 'Configuración',
  settings_subtitle: 'Todo sobre ti y tu empresa',
  settings_personal_settings: 'Configuración Personal',
  settings_security_settings: 'Configuración de Seguridad',
  settings_company_settings: 'Configuración de la Empresa',
  settings_name: 'Nombre',
  settings_email: 'Email',
  settings_language: 'Idioma',
  settings_company_name: 'Nombre de la empresa',
  settings_company_line1: 'Dirección - Línea 1',
  settings_company_line2: 'Dirección - Línea 2',
  settings_company_city: 'Ciudad',
  settings_company_postal_code: 'Código postal',
  settings_company_country: 'País',
  settings_company_website: 'Sitio web',
  settings_company_email: 'Correo electrónico',
  settings_company_vat_number: 'Número fiscal',
  settings_update_password: 'Actualizar Contraseña',
  settings_update: 'Actualizar',
  settings_update_personal: 'Actualizar datos personales',
  settings_update_company: 'Actualizar empresa',

  settings_personal_updated: 'Has actualizado tu configuración personal',
  settings_personal_update_error:
    'Hubo un error al actualizar tu configuración personal',
  settings_company_updated: 'Has actualizado la configuración de la empresa',
  settings_company_update_error:
    'Hubo un error al actualizar la configuración de la empresa',
  settings_close_password: 'Cancelar',
  settings_change_password: 'Actualizar contraseña',
  settings_set_password: 'Establecer contraseña',
  settings_current_password: 'Contraseña actual',
  settings_new_password: 'Nueva contraseña',
  settings_confirm_password: 'Confirmar contraseña',
  settings_password_updated: 'Has actualizado tu contraseña',
  settings_password_update_error: 'Hubo un error al actualizar tu contraseña',
  settings_update_profile: 'Actualiza tu perfil',
  settings_update_profile_name: 'Tu nombre',
  settings_update_profile_app_domain: 'El dominio de tu empresa',
  settings_update_profile_app_domain_description:
    'Este campo es necesario para que sepamos qué página abrir cuando comiences a editar tus recorridos de productos',
  settings_update_profile_password: 'Contraseña',
  settings_update_profile_save: 'Guardar',
  settings_profile_updated: 'Has actualizado tu perfil',
  settings_profile_update_error: 'Hubo un error al actualizar tu perfil',

  months: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ],
};

export default es;
