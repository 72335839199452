export default function auth(state = null, action) {
  // Default values
  if (state == null) {
    return {
      loading: true,
      authenticated: false,
      user: null,
      loggingOut: false,
      updated: null,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'NOT_AUTHENTICATED':
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: null,
      };

    case 'LOGIN_SUCCEEDED':
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: action.payload.user,
      };

    case 'LOGOUT_STARTED':
    case 'LOGOUT_COOKIE':
      return {
        ...state,
        loggingOut: true,
        loading: false,
        authenticated: false,
      };

    case 'LOGOUT_FINISHED':
      return {
        ...state,
        loggingOut: false,
        user: null,
      };

    case 'CLIENT_UPDATED':
      return {
        ...state,
        user: {
          ...state.user,
          client: action.data,
        },
        updated: new Date(),
      };
    case 'USER_PASSWORD_SAVED':
      return {
        ...state,
        user: {
          ...state.user,
          usesLoginLink: false,
        },
      };
    default:
      return state;
  }
}
