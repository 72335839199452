export default function layout(state = null, action) {
  // Default values
  if (state == null) {
    return {
      adminDashboardVisible: false,
      activeMenu: null,
      notVerifiedHidden: false,
    };
  }

  // Change state according to action type
  switch (action.type) {
    case 'ADMIN_DASHBOARD':
      return {
        ...state,
        adminDashboardVisible: action.visible,
      };

    case 'SET_ACTIVE_MENU':
      return {
        ...state,
        activeMenu: action.active,
      };

    case 'HIDE_NOT_VERIFIED':
      return {
        ...state,
        notVerifiedHidden: true,
      };

    default:
      return state;
  }
}
