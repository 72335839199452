/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import localized from '../../i18n';

import './style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import Close from '../../img/close.svg';
import CloseWhite from '../../img/close-white.svg';

const Popup = ({
  className,
  title,
  closeable,
  isOpen,
  onClose,
  children,
  danger,
  withoutHeader,
  withoutPadding,
}) => {
  const [wasShown, setWasShown] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setWasShown(true);
      document.body.style.overflow = 'hidden';
      document.getElementById('root').classList.remove('popup-closed');
      document.getElementById('root').classList.add('popup-opened');
    } else {
      document.body.style.overflow = 'auto';
      if (document.getElementById('root').classList.contains('popup-opened')) {
        document.getElementById('root').classList.remove('popup-opened');
        document.getElementById('root').classList.add('popup-closed');
      }
    }
  }, [isOpen]);

  return createPortal(
    <div
      className={classNames(
        `popup-container fixed top-0 left-0 right-0 bottom-0 z-30`,
        {
          in: isOpen,
          out: !isOpen && wasShown,
        },
      )}
      onClick={() => onClose()}
      role="dialog"
    >
      <div className="popup-backdrop w-full h-full bg-backdrop flex items-center justify-center">
        <div
          onClick={e => e.stopPropagation()}
          className={`popup z-20 no-cursor absolute
            rounded-sm overflow-hidden bg-faded-white shadow-2xl
            flex flex-col ${className}`}
          role="dialog"
        >
          {!withoutHeader && (
            <>
              <div
                className={`h-2 w-full rounded-t-sm flex items-center justify-center ${
                  danger ? 'bg-red-600' : 'bg-primary-600'
                }`}
              />
              <div
                className={`px-6 pt-2 pb-4 ${
                  danger ? 'bg-red-600' : 'bg-primary-600'
                }`}
              >
                <div className="flex items-center justify-between">
                  <p className="text-xl font-bold font-display text-white">
                    {title}
                  </p>
                  {closeable ? (
                    <button type="button" onClick={onClose} className="w-4 h-4">
                      {danger ? (
                        <CloseWhite alt={localized.close} />
                      ) : (
                        <Close alt={localized.close} />
                      )}
                    </button>
                  ) : (
                    <div className="w-1 h-1" />
                  )}
                </div>
              </div>
            </>
          )}
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
            }}
            containerRef={ref => {
              if (ref) {
                // eslint-disable-next-line no-param-reassign
                // eslint-disable-next-line no-underscore-dangle
                ref._getBoundingClientRect = ref.getBoundingClientRect;

                ref.getBoundingClientRect = () => {
                  // eslint-disable-next-line no-underscore-dangle
                  const original = ref._getBoundingClientRect();

                  return {
                    bottom: original.bottom,
                    left: original.left,
                    right: original.right,
                    top: original.top,
                    width: Math.floor(original.width),
                    _width: original.width,
                    height: Math.floor(original.height),
                    _height: original.height,
                    x: original.x,
                    y: original.y,
                  };
                };
              }
            }}
          >
            <div className="h-auto">
              <div className={`${!withoutPadding && 'px-6 py-4'}`}>
                {children}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>,
    document.body,
  );
};

Popup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  danger: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  withoutPadding: PropTypes.bool,
};

Popup.defaultProps = {
  className: '',
  title: '',
  isOpen: false,
  closeable: true,
  onClose: () => {},
  children: null,
  danger: false,
  withoutHeader: false,
  withoutPadding: false,
};

export default Popup;
